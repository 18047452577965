import React ,{useState ,useEffect} from 'react'


const CloseTrade = ({ deleteConfirmClose, type  ,deleteConfirmData }) => {
    // debugger
    // onClick={() => handleDeleteTrade(val.tradeId)}
    // const handleDeleteConfirm=()=>{
    //     debugger
    //     deleteConfirm(isCheckboxChecked,stopprice)
    // }

    const handleDeleteConfirm = () => {
        deleteConfirmClose({
            CloseWithMyPrice: isCheckboxChecked,
            ClosePrice:  parseFloat(stopprice),
            TradeId:  parseInt(deleteConfirmData?.transactionNo)
        });
    };
    const[stopprice ,setStopPrice] =useState(deleteConfirmData?.stopLossPrice)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleChangeStockInput = (e) => {
        setStopPrice(e.target.value)
		

	}
    useEffect(() => {
        // Update stoploss state when deleteConfirmData changes
        setStopPrice(deleteConfirmData?.stopLossPrice);
    }, [deleteConfirmData]);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };
    // console.log(type)
    return (
        <>
       
            <div className="modal fade show backdrop" data-bs-backdrop="static" id="closeTrade" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ 'display': 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 ps-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Are you sure?</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>

                        <div className='closeprice'>
                           
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showInputCheckbox"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="showInputCheckbox">
                                   Close Trade with StopPrice
                                </label>
                            </div>
                            {isCheckboxChecked && (
                            <div className='closeprice'>
                                <label>Close Price</label>
                                <input className="know-commper-bx-min w-100" name='stopLossPrice' value={stopprice} onChange={handleChangeStockInput} />
                            </div>
                        )}
                        </div>
                        <div className='col-12'>
                            <p className='white_text' >
                                Do you really want to {type} ? This process cannot be undone.
                            </p>
                        </div>
                        <div className='row'>
                        <div className='col-md-6'>
                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' data-bs-dismiss="modal" aria-label="Close">Cancel </button>
                        </div>
                        <div className='col-md-6'>
                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-provider' type='button' onClick={handleDeleteConfirm} data-bs-dismiss="modal" aria-label="Close" >Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
       
        </>
    )
}

export default CloseTrade
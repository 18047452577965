import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormikContext } from 'formik'
import * as Yup from "yup";
import moment from 'moment/moment'
import { getStrategy } from '../../../services/StrategyService';
import { getSearchStockDetails, getStockList } from '../../../services/HomeService';
import { GetCurrentBalabce, GetProviderCummission, GetProviderstocks, GetStockByCountry, getProviders } from '../../../services/ProviderServices';
import { AddOpenTradeAPI } from '../../../services/TradeService';
import CummissionDetails from '../../commonComponent/commonModels/CummissionDetails'
import { toast } from 'react-toastify';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
import { getCountryListAPI } from '../../../services/commonServices';
import { BsFillArrowUpRightCircleFill, BsArrowDownRightCircleFill } from 'react-icons/bs'
import { GetExchangeRate } from '../../../services/TradeService';
import $ from 'jquery'
import { IoReload } from "react-icons/io5";
const API_URL = process.env.REACT_APP_API_BASE_URL
const OpenTrade = ({ TransFee, ValueInShares, quantity, StopLossPrice, usercountry, currency, runAgainDashBoard, setRunAgainDashBoard, userType, providerList, portfololioList, openTradeRun, calculatedData, setCalculatedData }) => {
    const [loading, setLoading] = useState(false)
    const [spread, setSpread] = useState("");
    const [leverage, setleverage] = useState("");

    const [countryList, setCountryList] = useState()
    const [stockdetail, setStockDetail] = useState('');
    const [possibleprofit, setPossibleProfit] = useState(0);
    const [possibleprice, setPossiblePrice] = useState(0);
    const [increasecount, setIncreaseCount] = useState(0);
    const [isCancelOnDateChecked, setIsCancelOnDateChecked] = useState(false);
    const [showExecutePrice, setShowExecutePrice] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showStopLoss, setShowStopLoss] = useState(false);
    const [priceValue, setPriceValue] = useState(0);
    const [profitprice, setProfitPrice] = useState(0);
    const [lossprice, setLossPrice] = useState(0);
    const [DateValue, setDateValue] = useState('');
    const [value, setValue] = useState('2023-08-24');
    const [checkStop, setCheckStop] = useState('1')
    const [setExchangerate] = useState('');
    const [initialState, setInitialState] = useState({
        portfolioID: '',
        userId: userType?.userId,
        strategy: '',
        providerID: '',
        stockCode: "",
        forexCode: "",
        stockName: "",
        share_Sector: "Spot",
        country: '',
        margin: "",
        short: "buy" || "long",
        openDate: moment(new Date()).format('DD/MM/YYYY'),
        qty: '',
        transactionPrice: '',
        transFee: '',
        transCost: '',
        valueInCFD: '',
        valueInShares: '',
        comments: "",
        stopLossPrice: 0,
        gsloPrice: '',
        gsloCost: '',
        gsloTradingFee: '',
        fromTransPricePercent: '',
        fromTransPriceDollar: '',
        atRiskPercent: '',
        atRiskDollar: '',
        createdBy: "",
        isOpen: true,
        modifiedBy: "",
        brockerId: '',
        tradeId: 0,
        currency: "",
        Lotquantity: '',
        bidPrice: "",
        askprice: "",
        entryPrice: '',
        amountvalue: '',
        lotValue: ''
    })

    const { lotValue, entryPrice, amountvalue, transFee, portfolioID, providerID, forexCode, stockCode, valueInShares, transactionPrice, brockerId, margin, qty, short, gsloTradingFee, gsloPrice, gsloCost, stopLossPrice, Lotquantity } = initialState
    const [strategyLists, setStrategyLists] = useState([])
    const [selectedPortFolio, setSelectedPortFolio] = useState()
    const [showCodeList, setShowCodeList] = useState(false)
    const [stockLists, setStockLists] = useState([])
    const [cummissionData, setCummissionData] = useState([])
    const [brokerData, setBrokerData] = useState('')
    const [checkStopLoss, setCheckStopLoss] = useState(true)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [error, setError] = useState({ target: true, stopLoss: true })
    const [brokerList, setBrokerList] = useState(portfololioList)
    const [providerlists, setProviderLists] = useState(providerList)
    const [amount, setAmount] = useState(1);
    const [selectedproviderid, setSelectedProviderid] = useState("")
    const [checkInput, setCheckInput] = useState(false)
    const [checkAskPrLo, setCheckAskPrLo] = useState('same')
    const [checkBidPrLo, setCheckBidPrLo] = useState('same')
    const [checkPricePrLo, setCheckPricePrLo] = useState('same')
    const [shouldRunEffect, setShouldRunEffect] = useState(false);
    const [exchangerate, setExchangeRate] = useState(0)
    const [askprice, setAskPrice] = useState('')
    const [bidprice, setBidPrice] = useState('')
    const [usercurrency, setUserCurrency] = useState();
    const [data, setData] = useState({
        country: '',
        PageNumber: 1,
        PageSize: 200,
        search: '',
        currency: '',

    })

    let validationSchema = Yup.object().shape({
        entryPrice: Yup.string().required('AT Price is required'),
        portfolioID: Yup.string().required('Portfolio is required'),
        providerID: Yup.string().required('Provider is required'),
        forexCode: Yup.string().required('Forex code is required'),
        strategy: Yup.string().required('Strategy is required'),
        margin: Yup.string().required('Margin is required').typeError('Margin must be a number.'),
        stopLossPrice: Yup.string().required('Stop Price is required'),
        gsloPrice: Yup.string().typeError('GSLO price must be a number.'),
        lotValue: Yup.string().required('Lot value is required'),
        Lotquantity: Yup.string().required('Lot Amount is required'),
    });

    const [popUpShow, setPopUpShow] = useState(null)

    const showToast = (message) => {
        if (popUpShow) {
            toast.dismiss(popUpShow);
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
        } else {
            // If no active toast, show a new one
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
    };

    let filteredProviderId

    if (calculatedData !== '' && calculatedData !== undefined) {

        filteredProviderId = portfololioList.filter((item) => {
            if (item.portfolioId == calculatedData.portFolioId) {
                return item
            }
        })
    }
    useEffect(() => {

        setBrokerList(portfololioList)
    }, [portfololioList])

    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    setCountryList(res.data)



                }
            })
        } catch (error) {

        }
    }, [])

    useEffect(() => {

        if (calculatedData !== undefined && calculatedData !== '') {
            if (calculatedData?.portFolioId !== undefined) {
                let stkCryCode = calculatedData?.stockCode.split('/')
                let portFolioCountryCode = portfololioList.filter((val) => val?.portfolioId == calculatedData?.portFolioId)
                let exParam = {
                    CurrencyFrom: stkCryCode[1],
                    CurrencyTo: portFolioCountryCode[0]?.currency
                }
                try {
                    GetExchangeRate(exParam).then((res) => {
                        if (res?.status === 200) {
                            const exPrice = Number(res?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"]);
                            setExchangeRate(exPrice)
                            let n = (calculatedData?.short == 'buy' || calculatedData?.short == 'long' ? calculatedData?.ask_price : calculatedData?.bid_price) * 1000 * exPrice
                            let amtVal = twoDecimal(n.toString())
                            setInitialState({
                                ...initialState,
                                Lotquantity: 1000,
                                amountvalue: amtVal,
                                lotValue: 0.01,
                                portfolioID: calculatedData?.portFolioId || '',
                                userId: userType?.userId,
                                strategy: calculatedData?.tradingSystemId,
                                country: calculatedData?.country,
                                providerID: calculatedData?.providerId,
                                providerName: calculatedData?.providerName,
                                stockCode: calculatedData?.stockCode,
                                forexCode: calculatedData?.stockCode,
                                stockName: calculatedData?.stockCode,
                                share_Sector: "Stock",
                                margin: calculatedData?.margin,
                                short: calculatedData?.short,
                                openDate: moment(new Date()).format('DD/MM/YYYY'),
                                qty: calculatedData.quantity > 0 ? Math.round(calculatedData.quantity) : '',
                                transactionPrice: calculatedData?.entry,
                                stopLossPrice: calculatedData?.stopLoss,
                                transFee: 0,
                                transCost: 0,
                                valueInCFD: 0,
                                valueInShares: 0,
                                comments: "",
                                gsloPrice: 0,
                                gsloCost: 0,
                                gsloTradingFee: 0,
                                fromTransPricePercent: 0,
                                fromTransPriceDollar: 0,
                                atRiskPercent: 0,
                                atRiskDollar: 0,
                                createdBy: "",
                                isOpen: true,
                                modifiedBy: "",
                                tradeId: calculatedData?.tradeId,
                                bidPrice: calculatedData?.bid_price,
                                askprice: calculatedData?.ask_price,
                                entryPrice: calculatedData?.short == 'buy' || calculatedData?.short == 'long' ? calculatedData?.ask_price : calculatedData?.bid_price
                            })
                            setAskPrice(calculatedData?.ask_price)
                            setBidPrice(calculatedData?.bid_price)
                        }
                        // getAskPrice(afterSlash ,beforeSlash)
                    });
                } catch (err) {

                }
            } else {
                setInitialState({
                    ...initialState,
                    Lotquantity: 1000,
                    amountvalue: (calculatedData?.short == 'buy' || calculatedData?.short == 'long' ? calculatedData?.ask_price : calculatedData?.bid_price) * 1000 * 1,
                    lotValue: 0.01,
                    portfolioID: calculatedData?.portFolioId || '',
                    userId: userType?.userId,
                    strategy: calculatedData?.tradingSystemId,
                    country: calculatedData?.country,
                    providerID: calculatedData?.providerId,
                    providerName: calculatedData?.providerName,
                    stockCode: calculatedData?.stockCode,
                    forexCode: calculatedData?.stockCode,
                    stockName: calculatedData?.stockCode,
                    share_Sector: "Stock",
                    margin: calculatedData?.margin,
                    short: calculatedData?.short,
                    openDate: moment(new Date()).format('DD/MM/YYYY'),
                    qty: calculatedData.quantity > 0 ? Math.round(calculatedData.quantity) : '',
                    transactionPrice: calculatedData?.entry,
                    stopLossPrice: calculatedData?.stopLoss,
                    transFee: 0,
                    transCost: 0,
                    valueInCFD: 0,
                    valueInShares: 0,
                    comments: "",
                    gsloPrice: 0,
                    gsloCost: 0,
                    gsloTradingFee: 0,
                    fromTransPricePercent: 0,
                    fromTransPriceDollar: 0,
                    atRiskPercent: 0,
                    atRiskDollar: 0,
                    createdBy: "",
                    isOpen: true,
                    modifiedBy: "",
                    tradeId: calculatedData?.tradeId,
                    bidPrice: calculatedData?.bid_price,
                    askprice: calculatedData?.ask_price,
                    entryPrice: calculatedData?.short == 'buy' || calculatedData?.short == 'long' ? calculatedData?.ask_price : calculatedData?.bid_price
                })

                setAskPrice(calculatedData?.ask_price)
                setBidPrice(calculatedData?.bid_price)
            }
        }
    }, [calculatedData, portfololioList])

    useEffect(() => {
        
        if (providerID !== '' && providerID !== undefined) {

            try {
                GetProviderCummission(providerID).then((res) => {
                    if (res?.status === 200) {
                        setCummissionData(res.data)
                    }
                })
            } catch (error) {

            }
        }
    }, [providerID])



    const getprovider = () => {
        getProviders(data).then((res) => {
            if (res?.status === 200) {

                setProviderLists(res.data.data)

            }
        })


    }
    useEffect(() => {
        getprovider();

    }, []);



    const getProviderStockList = () => {
        let provdata = {
            PageNumber: 1,
            PageSize: 100,
            id: providerID,
            search: ''
        }
        if(provdata.id !== undefined && provdata.id !== "" && provdata.id >0 ){
    
        
        GetProviderstocks(provdata).then((res) => {
            if (res?.status == 200) {

                setStockLists(res.data.data)
                //GetProviderstocks(res.data.data)
            }
        })

    }
    }
    useEffect(() => {
        getProviderStockList()
    }, [providerID])



    const handleChangeOpenInputProvider = (e) => {
        setInitialState({ ...initialState, [e.target.name]: e.target.value })
        setSelectedProviderid(e.target.value)
    }




    const getStrategyList = () => {
        try {
            getStrategy(userType?.userId).then((res) => {
                if (res?.status === 200) {
                    setStrategyLists(res.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        // if (userType?.userId!==undefined) {
        getStrategyList()
        // }
    }, [openTradeRun, userType])

    const handleChangeOpenInput = (e) => {

        if (e.target.name == 'portfolioID') {
            // debugger
            if (e.target.value == '') {
                setInitialState({ ...initialState, [e.target.name]: '', entryPrice: entryPrice, currency: '' })
            } else {
                let selectedPortFolio = portfololioList.filter((val) => e.target.value == val.portfolioId)
                let selectedCountry = countryList.filter((val) => val.key == selectedPortFolio[0].country)
                let symboleData
                if (selectedCountry.length > 0) {
                    symboleData = CountryCurrencyList.filter((val) => val.code == selectedCountry[0].value)


                }
                if (forexCode != undefined && forexCode.length > 0) {
                    let stkArr = forexCode.split('/')
                    let prmData = {
                        CurrencyFrom: stkArr[1],
                        CurrencyTo: selectedPortFolio[0]?.currency

                    }
                    try {
                        GetExchangeRate(prmData).then((respons) => {

                            const exPrice = Number(respons?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"]);

                            setExchangeRate(exPrice);
                            let amtVal = twoDecimal((entryPrice * Lotquantity * exPrice).toString())
                            setInitialState({
                                ...initialState,
                                [e.target.name]: selectedPortFolio[0].portfolioId,
                                entryPrice: entryPrice,
                                currency: selectedCountry[0].value,
                                amountvalue: amtVal
                            })

                        })
                    } catch (error) {

                    }
                } else {
                    setInitialState({ ...initialState, [e.target.name]: selectedPortFolio[0].portfolioId, entryPrice: entryPrice, currency: selectedCountry[0].value })
                }
                setData({ ...data, country: selectedPortFolio[0].country, currency: selectedCountry[0].value })
                setUserCurrency(selectedCountry[0].value)

                setSelectedPortFolio(selectedPortFolio[0])
            }



        } else if (e.target.name == 'qty') {
            // debugger

            if (e.target.value >= 0 && e.target.value != '') {
                let vs = transactionPrice * Math.round(e.target.value)

                if (brokerData?.currentBalance > vs && forexCode !== '') {
                    setInitialState({ ...initialState, [e.target.name]: Math.round(e.target.value) })
                } else if (portfolioID == "undefined-undefined") {
                    showToast('Select portfolio first!')
                } else if (forexCode == '') {
                    showToast('Select stock first!')
                }
                //  else if (transactionPrice == 'NaN') {
                //     showToast('Please enter entry price first.')
                // } 
                // else {
                //     showToast('Does not have sufficient balance!')
                // }
            } else if (e.target.value == '') {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
            // else{
            //     showToast('Quantity must be a number.')
            // }
        } else if (e.target.name == 'transactionPrice') {
            if (Number(e.target.value) >= 0 && e.target.value != '') {
                let vs = qty * e.target.value
                if (brokerData?.currentBalance > vs && stockCode !== '') {
                    let value = ''
                    let n = e.target.value.split('.')
                    let roundedNumber = twoDecimal(e.target.value)
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setInitialState({ ...initialState, [e.target.name]: roundedNumber, stopLossPrice: 0 })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setInitialState({ ...initialState, [e.target.name]: roundedNumber, stopLossPrice: 0 })
                    }

                } else if (portfolioID == "undefined") {
                    showToast('Select portfolio first!')
                } else if (forexCode == '') {
                    showToast('Select stock first!')
                } else {
                    showToast('Does not have sufficient balance!')
                }
            } else if (e.target.value == '') {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
            // else{
            //     showToast('Quantity must be a number.')
            // }
        } else if (e.target.name == 'margin' || e.target.name == 'gsloPrice') {
            if (e.target.value >= 0 && e.target.value != "") {
                let value = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setInitialState({ ...initialState, [e.target.name]: value })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setInitialState({ ...initialState, [e.target.name]: value })
                }

            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
        }
        else if (e.target.name == 'stopLossPrice') {
            if (e.target.value >= 0 && e.target.value != "") {
                let value = twoDecimal(e.target.value)
                if (short == 'false') {
                    if (Number(transactionPrice) <= Number(e.target.value)) {
                        showToast('Set lower stop loss price than entry while long trading')
                    } else {
                        setInitialState({ ...initialState, [e.target.name]: value })
                    }
                } else {
                    setInitialState({ ...initialState, [e.target.name]: value })
                }
            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: 0 })
            }

        } else if (e.target.name == 'gsloCost') {
            if (e.target.value >= 0 && e.target.value != "") {
                let value = twoDecimal(e.target.value)
                setInitialState({ ...initialState, [e.target.name]: value })
            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: 0 })
            }
        } else if (e.target.name == 'spread') {
            setSpread(e.target.value)
        }
        else if (e.target.name == 'leverage') {
            setleverage(e.target.value)
        }
        else {
            setInitialState({ ...initialState, [e.target.name]: e.target.value })

        }
    }
    const handleGetSequrityName = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;

        if (
            (scrollTop + clientHeight) >= scrollHeight &&
            (scrollTop + clientHeight) <= scrollHeight + 100
        ) {
            let nm = data
            nm.PageNumber = nm.PageNumber + 1
            setData({ ...nm })
            if (data.country.length > 0 && stockLists.length >= 200) {
                try {
                    GetStockByCountry(nm).then((res) => {
                        if (res?.status === 200) {
                            setStockLists(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }

        } else if (
            (scrollTop + clientHeight) <= scrollHeight &&
            scrollTop <= 10
        ) {
            let nm = data
            nm.PageNumber = nm.PageNumber > 1 ? nm.PageNumber - 1 : nm.PageNumber
            setData({ ...nm })
            if (data.country.length > 0 && stockLists.length >= 200) {
                try {
                    GetStockByCountry(nm).then((res) => {
                        if (res?.status === 200) {
                            setStockLists(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }
        }
        // setInitialState({ ...initialState, stockName: e.target.value })
        // 
    }
    // setInitialState({ ...initialState, providerID: values.providerID })
    // const handleStockData = (value) => {

    //     setShowCodeList(false)
    //     try {
    //         const forexSymbolWithoutSlash = value.forexSymbol.replace('/', '');
    //         // let combinedValue = forexSymbolWithoutSlash + "";
    //         setLotValue(0.1)
    //         setAmount(10000)

    //         getSearchStockDetails(forexSymbolWithoutSlash, amount).then((res) => {

    //             if (res?.status === 200) {

    //                 let aus = value.forexSymbol.includes(".AX")
    //                 if (aus) {
    //                     if (res.data.close >= 0) {

    //                         setInitialState({ ...initialState, providerID: value?.providerId, transactionPrice: Number(res.data?.close).toFixed(2), country: value.country, stockName: value.stockName, margin: value.margin, stockCode: value.forexSymbol })
    //                     } else {
    //                         showToast('Please select valid stock.')
    //                     }
    //                 } else if (res.data.length > 0) {
    //                     setInitialState({ ...initialState, providerID: value?.providerId, transactionPrice: Number(res.data[0]?.close).toFixed(2), country: value.country, stockName: value.stockName, margin: value.margin, stockCode: value.forexSymbol })
    //                 } else {
    //                     showToast('Please select valid stock.')
    //                 }
    //             }
    //         })
    //     } catch (error) {

    //     }
    // }


    //const [result, setResult] = useState(0)
    const [slash, setSlash] = useState();

    // useEffect(() => {
    //     if (amount && rate) {
    //         const calculatedResult = parseFloat(amount) * parseFloat(rate);
    //         setResult(calculatedResult);
    //     } else {
    //         setResult(0);
    //     }
    // }, [amount, rate]);

    // const exchangeRate = () => {
    //     const CurrencyFrom = 'USD'; 
    //     const CurrencyTo = 'EUR';  

    //     GetExchangeRate({ CurrencyFrom, CurrencyTo }).then((res) => {
    //       if (res?.status === 200) {
    //       }
    //     });
    //   };
    // useEffect(()=>{
    //     exchangeRate();
    // },[])

    const handleSearchStock = (e) => {
        //const searchValue = e.target.value;

        // Check if a provider has been selected
        if (!providerID) {
            // Display a message asking the user to select a provider
            showToast('Select Provider first!')
            return;
        }

        const nm = {
            id: selectedproviderid,
            PageNumber: 1,
            PageSize: 200,
            search: e.target.value,
        };
if(nm.id !== undefined){


        try {
            GetProviderstocks(nm)
                .then((res) => {
                    if (res?.status === 200) {
                        setShowCodeList(true);

                        setStockLists(res.data.data);
                        // let spreadvalue = jsondata.askPrice - jsondata.bidPrice
                        // setSpread(spreadvalue);

                    } else {

                    }
                })
                .catch((error) => {

                });
        } catch (error) {

        }
    }

    };

    const [exchangeforexsymbol, setexchangeforexsymbol] = useState([])
    const handleStockData = async (value) => {

        if (!portfolioID) {
            showToast('Select Portfolio first!')
            return;
        }

        try {



            const symbol = value.forexSymbol.split('/');
            const beforeSlash = symbol[0];
            const afterSlash = symbol[1];

            let forexSymbol = {
                CurrencyFrom: beforeSlash,
                CurrencyTo: afterSlash
            }

            // portfolioID
            let selectedPort = portfololioList.filter((val) => val?.portfolioId == portfolioID)
            let exchange = {
                CurrencyFrom: afterSlash,
                CurrencyTo: selectedPort[0]?.currency
            }
            setexchangeforexsymbol(exchange)

            await GetExchangeRate(forexSymbol).then((res) => {
                if (res?.status === 200) {
                    setShowCodeList(false);
                    try {

                        GetExchangeRate(exchange).then((resp) => {
                            if (resp?.status === 200) {
                                const exPrice = Number(resp?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"]);
                                let ask = Number(res?.data['Realtime Currency Exchange Rate']?.['9. Ask Price'])
                                let bid = Number(res?.data['Realtime Currency Exchange Rate']?.['8. Bid Price'])

                                //   console.log( Number(exPrice), "exchangerate")
                                setExchangeRate(exPrice)
                                // debugger
                                if (ask !== undefined && bid !== undefined) {
                                    let n = (initialState.short == 'buy' || initialState.short == 'long' ? ask : bid) * 1000 * exPrice
                                    let amtVal = twoDecimal(n.toString())
                                    setInitialState({
                                        ...initialState,
                                        providerID: value?.providerId,
                                        forexCode: value.forexSymbol,
                                        country: value.country,
                                        entryPrice: initialState.short == 'buy' || initialState.short == 'long' ? ask : bid,
                                        amountvalue: amtVal,
                                        Lotquantity: 1000,
                                        lotValue: 0.01,
                                        margin: value?.margin,

                                    });
                                    setAskPrice(ask);
                                    setBidPrice(bid);
                                }
                                else {
                                    setAskPrice(null)
                                    setBidPrice(null)
                                }
                            }
                            // getAskPrice(afterSlash ,beforeSlash)
                        });
                    } catch (err) {

                    }
                    // const askPrice = res.data['Realtime Currency Exchange Rate']['9. Ask Price'];
                    // const bidPrice = res.data['Realtime Currency Exchange Rate']['8. Bid Price'];






                }
                // getAskPrice(afterSlash ,beforeSlash)
            });

        } catch (error) {
            // Handle any other errors that may occur
        }

    };

    useEffect(() => {

        if (askprice !== null && initialState.Lotquantity != null) {
            const Value = (Number(initialState.Lotquantity) * Number(entryPrice) * exchangerate);
            let basecurrency, quotecurrency;
            if (initialState.forexCode) {
                basecurrency = initialState.forexCode.split('/')[1];
                quotecurrency = initialState.forexCode.split('/')[0];
            } else {
            }
            if (!isNaN(Value) && Value != 0) {
                setInitialState({
                    ...initialState,
                    amountvalue: Value
                })
            } else {
                setInitialState({
                    ...initialState,
                    amountvalue: ''
                })
            }
        }
        //setShouldRunEffect(false);
    }, [entryPrice]);
    const handleLongShort = (val) => {
        if (val === 'long') {
            setInitialState({ ...initialState, short: "false", stopLossPrice: 0 })
        } else if (val === 'short') {
            // setLongshort(false)
            setInitialState({ ...initialState, short: "true", stopLossPrice: 0 })

        }
    }

    const settingData = (data) => {
        let tpd, tpp, atrp, atrd
        if (stopLossPrice > 0 && stopLossPrice !== '') {
            if (short == 'true') {
                tpd = stopLossPrice - transactionPrice
            } else {
                tpd = transactionPrice - stopLossPrice
            }
        } else if (gsloPrice > 0 && gsloPrice !== '') {
            if (short == 'true') {
                tpd = gsloPrice - transactionPrice
            } else {
                tpd = transactionPrice - gsloPrice
            }
        }
        if ((stopLossPrice > 0 && stopLossPrice !== '') || (gsloPrice > 0 && gsloPrice !== '')) {
            tpp = (tpd / transactionPrice) * 100
            atrd = qty * tpd
            atrp = (atrd / brokerData?.currentBalance) * 100
            setInitialState({ ...initialState, ...data, fromTransPricePercent: tpp?.toFixed(3), fromTransPriceDollar: tpd?.toFixed(2), atRiskPercent: atrp ? atrp?.toFixed(2) : 0, atRiskDollar: atrd?.toFixed(2) })
        } else {
            setInitialState({ ...initialState, ...data })
        }

    }

    useEffect(() => {

        if (qty != '' && qty != undefined && transactionPrice != "" && transactionPrice != "NaN") {

            let vs = 0, tf = 0, vcfd = 0, tc = 0
            vs = transactionPrice * Number(qty)
            for (let i = 0; i < cummissionData.length; i++) {
                if (cummissionData[i].upToTransValue >= vs) {
                    if (cummissionData[i].type == "Percent") {
                        tf = (vs * cummissionData[i].commission) / 100
                    } else {
                        tf = cummissionData[i].commission
                    }
                    break;
                } else {
                    if (cummissionData[i].type == "Percent") {
                        tf = (vs * cummissionData[i].commission) / 100
                    } else {
                        tf = cummissionData[i].commission
                    }
                    break;
                }
            }
            let gtf = 0
            if (Number(gsloCost) > 0 && gsloCost != '') {
                gtf = (gsloCost * qty) / 100
            }
            vcfd = (vs * margin) / 100
            tc = vcfd + tf + gtf
            let data = {
                gsloTradingFee: gtf, valueInShares: Number(vs)?.toFixed(2), transFee: tf?.toFixed(2), valueInCFD: vcfd?.toFixed(2), transCost: tc?.toFixed(2)
            }
            settingData(data)
        } else {
            let data = {
                gsloTradingFee: 0, valueInShares: 0, transFee: '', valueInCFD: 0, transCost: 0
            }
            settingData(data)
        }
    }, [qty, gsloTradingFee, transactionPrice, margin, cummissionData, gsloCost, stopLossPrice, gsloPrice])

    const handleAddEditPortfolio = (value) => {

        setShowSaveButton(true)

        let apiData = {
            portfolioID: Number(value.portfolioID),
            country: value.country.toUpperCase(),
            userId: userType?.userId,
            strategy: Number(value.strategy),
            providerID: Number(value.providerID),


            stockCode: value.stockCode,
            stockName: value.stockName,
            share_Sector: value.share_Sector,
            margin: value.margin.toString(),
            short: value.short,
            openDate: new Date(),
            qty: Number(value.qty),
            transactionPrice: Number(value.transactionPrice),
            transFee: Number(value.transFee),
            transCost: Number(value.transCost),
            valueInCFD: Number(value.valueInCFD),
            valueInShares: Number(value.valueInShares),
            comments: value.comments,
            stopLossPrice: Number(value.stopLossPrice),
            gsloPrice: Number(value.gsloPrice),
            gsloCost: Number(value.gsloCost),
            gsloTradingFee: Number(value.gsloTradingFee),
            fromTransPricePercent: Number(value.fromTransPricePercent),
            fromTransPriceDollar: Number(value.fromTransPriceDollar),
            atRiskPercent: Number(value.atRiskPercent),
            atRiskDollar: Number(value.atRiskDollar),
            createdBy: "",
            isOpen: true,
            modifiedBy: "",
            tradeId: initialState?.tradeId || 0
        }
        let paramData = {
            userId: userType?.userId,
            portfolioId: value.portfolioID
        }
        try {

            GetCurrentBalabce(paramData).then((res) => {

                if (res?.status === 200) {
                } else {

                }
            })
        } catch (error) {

        }
    }
    const handleRemoveOpenData = () => {
        setShowSaveButton(false)
        setInitialState({
            entryPrice: '',
            forexCode: '',
            portfolioID: '',
            userId: "1",
            strategy: '',
            providerID: '',
            stockCode: "",
            stockName: "",
            share_Sector: "Stock",
            margin: "",
            short: "buy" || "long",
            openDate: moment(new Date()).format('DD/MM/YYYY'),
            qty: '',
            transactionPrice: '',
            transFee: '',
            transCost: '',
            valueInCFD: '',
            valueInShares: '',
            comments: "",
            stopLossPrice: '',
            gsloPrice: '',
            gsloCost: '',
            gsloTradingFee: '',
            fromTransPricePercent: '',
            fromTransPriceDollar: '',
            atRiskPercent: '',
            atRiskDollar: '',
            createdBy: "",
            isOpen: true,
            modifiedBy: "",
            brockerId: '',
            amountvalue: '',
            lotValue: '',
            Lotquantity: ''
        })
        setStockLists([])
        setData({ ...data, search: '' })
        setCalculatedData()
        setAskPrice('')
        setBidPrice('')
        setCheckStop('1')
    }


    const handleChangeTradeType = (val) => {
        

        if (val === 'buy' || val === 'long') {
            // setExchangeRate(exchangerate)


            setInitialState({
                ...initialState,
                entryPrice: askprice,
                Lotquantity: '', stopLossPrice: '', short: val, amountvalue: '',
                lotValue: ''
            })


        } else if (val === 'sell' || val === 'short') {


            setInitialState({
                ...initialState,
                entryPrice: bidprice,
                bidPrice: bidprice,
                Lotquantity: '', stopLossPrice: '', short: val, amountvalue: '', lotValue: ''
            })
            // setTradeInput({
            //     ...tradeInput,
            //     TransFee: cryptoSelectValue?.bid_price,
            //     ValueInShares: '', quantity: '', StopLossPrice: '', Short: val
            // })

        }
        //setCheckInput(false)
    }
    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 5) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
            }
        }
        // let nm=parseFloat(roundedNumber.toString())
        return roundedNumber
    }



    const handleChengeTradeInput = (e) => {

        if (!providerID) {
            // Display a message asking the user to select a provider
            showToast('Select Provider first!')
            return;
        }
        if (!providerID) {
            // Display a message asking the user to select a provider
            showToast('Select Provider first!')
            return;
        }
        // debugger

        if (e.target.name == 'lotValue') {

            if (Number(e.target.value) >= 0 && e.target.value != '') {
                const newLotValue = e.target.value;
                const lotValueFloat = parseFloat(newLotValue);
                let calculatedAmount = lotValueFloat * 100000;
                let nm = entryPrice * calculatedAmount * exchangerate
                let n = e.target.value.split('.')
                let lqtValue = twoDecimal(calculatedAmount.toString())
                let ltValue = twoDecimal(newLotValue.toString())
                let amtValRounded = twoDecimal(nm.toString())
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setInitialState({
                            ...initialState,
                            amountvalue: amtValRounded,
                            Lotquantity: lqtValue,
                            lotValue: ltValue
                        })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setInitialState({
                        ...initialState,
                        amountvalue: amtValRounded,
                        Lotquantity: lqtValue,
                        lotValue: ltValue
                    })

                }
            } else if (e.target.value == '') {
                setInitialState({
                    ...initialState,
                    amountvalue: '',
                    Lotquantity: '',
                    lotValue: ''
                })
            }

        }
        else if (e.target.name == 'AmountLot') {
            if (Number(e.target.value >= 0) && e.target.value != '') {
                let lotValueFloat = 0
                let nm = ''
                if (Number(e.target.value) >= 0) {

                    lotValueFloat = Number(e.target.value) / 100000;
                    //setAmountValue(Number(e.target.value) * exchangerate)

                    //setAmountValue(e.target.value  * exchangerate)
                    // setAmountValue(Lotquantity * exchangerate)
                    if (typeof exchangerate === 'number' && !isNaN(exchangerate)) {
                        const Value = (Number(e.target.value) * Number(exchangerate) * entryPrice);
                        let basecurrency = slash;
                        let quotecurrency = data.currency;
                        let newForexSymbol = basecurrency + quotecurrency;

                        if (!isNaN(Value)) {
                            nm = (Value);
                        }

                        // let vs = exchangerate * e.target.value
                        // //         setTradeInput({
                        // //             ...tradeInput, [e.target.name]: e.target.value, ValueInShares: vs
                        // //         })
                        // setAmountValue(vs) 
                    }
                }
                let n = e.target.value.split('.')
                let nmValue = twoDecimal(e.target.value.toString())
                let ltValue = twoDecimal(lotValueFloat.toString())

                let amtValRounded = twoDecimal(nm.toString())
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setInitialState({
                            ...initialState,
                            amountvalue: amtValRounded,
                            Lotquantity: nmValue,
                            lotValue: ltValue
                        })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setInitialState({
                        ...initialState,
                        amountvalue: amtValRounded,
                        Lotquantity: nmValue,
                        lotValue: ltValue
                    })
                }
            } else if (e.target.value == '') {
                setInitialState({
                    ...initialState,
                    amountvalue: '',
                    Lotquantity: '',
                    lotValue: ''
                })
            }

        } else if (e.target.name == 'stopLossPrice') {
            if (Number(e.target.value >= 0) && e.target.value != '') {
                if (short == 'buy' || short == 'long') {
                    if (e.target.value < entryPrice) {
                        let n = e.target.value.split('.')
                        let value = twoDecimal(e.target.value)
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setInitialState({
                                    ...initialState,
                                    [e.target.name]: value
                                })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setInitialState({
                                ...initialState,
                                [e.target.name]: value
                            })
                        }
                    } else {
                        showToast('Set lower stoploss than at price while buy trading')
                    }
                } else {
                    let n = e.target.value.split('.')
                    let value = twoDecimal(e.target.value)
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setInitialState({
                                ...initialState,
                                [e.target.name]: value
                            })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setInitialState({
                            ...initialState,
                            [e.target.name]: value
                        })
                    }
                }
            } else if (e.target.value == '') {
                setInitialState({
                    ...initialState,
                    [e.target.name]: ''
                })
            }


        } else {
            if (Number(e.target.value >= 0) && e.target.value != '') {


                let n = e.target.value.split('.')
                let value = twoDecimal(e.target.value)
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setInitialState({
                            ...initialState,
                            [e.target.name]: value
                        })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setInitialState({
                        ...initialState,
                        [e.target.name]: value
                    })
                }
            } else if (e.target.value == '') {
                setInitialState({
                    ...initialState,
                    [e.target.name]: ''
                })
            }

        }
    }

    const handletradeBuySell = () => {
        // debugger
        if (checkStop == '0') {
            if (short == 'buy' || short == 'long') {

                if (askprice !== '' && amountvalue !== '' && Lotquantity !== '' && portfolioID !== '' && forexCode !== '') {
                    //setCheckInput(false)
                    BuyTrade()
                }
            }
            else {
                if (bidprice !== '' && amountvalue !== '' && Lotquantity !== '' && portfolioID !== '' && forexCode !== '')
                //setCheckInput(false)
                {
                    BuyTrade()
                }
            }
        }
        else if (checkStop == '1') {
            if (short == 'buy' || short == 'long') {
                if (askprice !== '' && amountvalue !== '' && Lotquantity !== '' && stopLossPrice !== '' && portfolioID !== '' && forexCode !== '') {
                    //setCheckInput(false)
                    BuyTrade()
                }
            } else {
                if (bidprice !== '' && amountvalue !== '' && Lotquantity !== '' && stopLossPrice !== '' && portfolioID !== '' && forexCode !== '')
                //setCheckInput(false)
                {
                    BuyTrade()
                }
            }
        }
    }
    const validationStoploss = (e) => {
        if (e.target.name == 'stopLossPrice') {
            if (short == 'buy' || short == 'long') {
                if (askprice > Number(e.target.value)) {
                } else {
                    showToast('Set lower stoploss than at price while buy trading')
                }
            }
            else if (short == 'sell' || short == 'short') {
                if (bidprice < Number(e.target.value)) {
                } else {
                    showToast('Set higher stoploss than at price while sell trading')
                }
            }
        }
    }
    const RefreshExchangeRate = () => {
        setLoading(true);
        let selectedPort = portfololioList.filter((val) => val?.portfolioId == portfolioID)
        // let exchange = {
        //     CurrencyFrom: afterSlash,
        //     CurrencyTo: selectedPort[0]?.currency
        // }
        const forexSymbol = forexCode.split('/');
        const beforeSlash = forexSymbol[1];
        const afterSlash = selectedPort[0]?.currency;
        let params = {
            CurrencyFrom: beforeSlash,
            CurrencyTo: afterSlash
        }
        GetExchangeRate(params)
            .then((response) => {
                if (response?.status === 200) {
                    const exchange = Number(response?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate'] || 1);
                    setExchangerate(exchange);
                }
            })
            .catch((error) => {
                // Handle error if needed
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleRefresh = () => {
        RefreshExchangeRate();
    }
    const BuyTrade = () => {
        
        let buysell
        if (initialState.short == 'buy') {
            buysell = "long"
        }
        else if (initialState.short == 'sell') {
            buysell = "short"
        }
        else {
            buysell = initialState.short
        }
        let BuyData = {
            portfolioID: portfolioID,
            userId: userType?.userId,
            country: data.country,
            tradeId: 0,
            providerID: providerID,
            stockCode: forexCode,
            short: buysell,
            openDate: new Date(),
            share_Sector: "",
            strategy: 0,
            margin: initialState?.margin.toString(),
            quantity: initialState.Lotquantity.toString(),
            entryPrice: initialState.entryPrice.toString(),
            ask_price: askprice.toString(),
            bid_price: bidprice.toString(),
            stockName: "",
            ask_size: "",
            country: "",
            bid_size: "",
            type: "",
            transFee: 0,
            transCost: 0,
            valueInCFD: 0,
            comments: "",
            stopLossPrice: stopLossPrice,
            gsloPrice: 0,
            gsloCost: 0,
            valueInShares: amountvalue.toString(),
            createdBy: "",
            isOpen: true,
            modifiedBy: "",
            tradeId: initialState?.tradeId || 0,
            target: '',
            tradeDate: ''
        }
        let paramData = {
            userId: userType?.userId,
            portfolioId: portfolioID
        }
        setLoading(true);

        try {
            GetCurrentBalabce(paramData).then((res) => {
                if (res?.status === 200) {
                    if (amountvalue < res.data.currentBalance) {
                        
                        try {
                            AddOpenTradeAPI(BuyData).then((res) => {
                                if (res?.status === 200) {
                                    if (res?.status === 200) {
                                        let optmClose = document.getElementById('openTradeModelClose')
                                        optmClose.click()
                                        setRunAgainDashBoard(runAgainDashBoard + 1)
                                        setShowSaveButton(false)
                                    }
                                } else {
                                    setShowSaveButton(false)
                                }
                            }).finally(() => {
                                setLoading(false);
                            });
                        } catch (error) {
                            setLoading(false);
                        }
                    } else {
                        showToast('Your transaction has been declined due to insufficient funds.')
                    }
                } else {

                }
            })
        } catch (error) {

        }
    }

    const handleBlurOpenInput = () => {

    }
    const handleBlurForexCode = () => {
        // debugger
        setShowCodeList(false)
    }
    const handleChangeStopLimit = (e) => {
        setCheckStop(e.target.value)
        if (e.target.value == '0') {

            setInitialState({
                ...initialState,
                stopLossPrice: 0
            })
        } else {
            setInitialState({
                ...initialState,
                stopLossPrice: ''
            })
        }
        // stopLossPrice
    }
    const handleClickShoeStockList = (val) => {

        setShowCodeList(!showCodeList)
    }
    const handleRemoveDrapdownListStock = () => {
        // setTimeout(() => {
        //     setShowCodeList(false)
        // }, 1000)
    }
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdownO > .captionO").length === 0) {
                setShowCodeList(false)
            }
        });
    });
    return (
        <>
            <div class="modal fade hide backdrop-1" data-bs-backdrop="static" id="openTrade" tabindex="-1" aria-labelledby="exampleModalLgLabel" aria-modal="true" role="dialog" style={{ "display": "none" }}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content cal-model">
                        <div class="modal-header">
                            <h5 class="modal-title h4 white_text" id="openTradeLabel">Open Trade</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" id="openTradeModelClose" aria-label="Close" onClick={handleRemoveOpenData} >×</button>
                        </div>
                        <div class="modal-body">
                            {/* <!-- trs det --> */}
                            <div class="row">
                                <div class="col-sm-12 p-3">
                                    <div class="postion-bx-min mt-2">
                                        <span class="frm-hd-t">Forex Trade Details</span>
                                    </div>
                                    <Formik
                                        initialValues={initialState}
                                        enableReinitialize={true}
                                        validationSchema={validationSchema}
                                        onSubmit={(value) => {
                                            handletradeBuySell(value)
                                        }}
                                    >
                                        {({ errors, touched, values }) => (
                                            <Form >

                                                <div class="row mt-1">
                                                    <div class="col-sm-12">
                                                        <div class="postion-min-dta postion-frm">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="row">
                                                                        <div class="col-sm-6">


                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Provider <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto position-relative">
                                                                                    <Field name='providerID' as='select' onBlur={handleBlurOpenInput} className={"form-select"} onChange={handleChangeOpenInputProvider}>
                                                                                        <option value="" style={{ visibility: "hidden;" }}>Select Provider</option>
                                                                                        {
                                                                                            providerlists?.map((val, ind) => {
                                                                                                return (
                                                                                                    <option key={ind} value={val.providerId}>{val.providerName}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Field>
                                                                                    {errors.providerID && touched.providerID ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.providerID}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3" >
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Forex Code <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto dropdownO position-relative">
                                                                                    <Field name='forexCode' type="select" onBlur={handleRemoveDrapdownListStock} autoComplete="off" placeholder="eg. EUR/USD" className={"form-select captionO"} list="brow" onKeyUp={handleSearchStock} onClick={() => handleClickShoeStockList(showCodeList)} style={{ cursor: "pointer" }} />

                                                                                    {errors.forexCode && touched.forexCode ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.forexCode}</div>
                                                                                    ) : null}

                                                                                    {
                                                                                        (showCodeList && stockLists.length > 0) &&
                                                                                        <div className='position-relative'  >
                                                                                            <div id='brow'

                                                                                                // onScroll={handleGetSequrityName}
                                                                                                style={{ display: showCodeList === true ? 'block' : 'none', 'width': '162px' }}>

                                                                                                {
                                                                                                    stockLists?.map((val, ind) => {
                                                                                                        return (
                                                                                                            <p key={ind} value={(val.forexSymbol)} onClick={() => handleStockData(val)}>{val.forexSymbol}</p>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {/* } */}


                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Portfolio Name <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto position-relative">
                                                                                    <Field name='portfolioID' onBlur={handleBlurOpenInput} as='select' className={"form-select"} onChange={handleChangeOpenInput}>
                                                                                        <option value="" style={{ visibility: "hidden;" }}>Select Portfolio</option>
                                                                                        {
                                                                                            brokerList?.map((val, ind) => {
                                                                                                return (
                                                                                                    <option key={ind} value={val.portfolioId}>{val.portfolioName}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Field>
                                                                                    {errors.portfolioID && touched.portfolioID ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.portfolioID}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Margin <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto position-relative">
                                                                                    <Field type="number" class="form-control" onBlur={handleBlurOpenInput} name='margin' value={margin} id="autoSizingInputMargin" placeholder="" onChange={handleChangeOpenInput} />
                                                                                    {errors.margin && touched.margin ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.margin}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-6">
                                                                    <div class="row">

                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Trade Type</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field name='share_Sector' as='select' className={errors.share_Sector && touched.share_Sector ? "form-select border-red" : "form-select"} onChange={handleChangeOpenInput}>
                                                                                        <option value="Spot" >Spot</option>
                                                                                        <option value="CFDs" >CFDs</option>
                                                                                        <option value="Future" >Future</option>
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Strategy Name <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto position-relative">
                                                                                    <Field name='strategy' onBlur={handleBlurOpenInput} as='select' className={"form-select"} onChange={handleChangeOpenInput}>
                                                                                        <option value="0" style={{ visibility: "hidden;" }}>Select Strategy</option>
                                                                                        {
                                                                                            strategyLists.map((val, ind) => {
                                                                                                return (
                                                                                                    <option key={ind} value={(val.tradingSystemId)}>{val.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Field>
                                                                                    {errors.strategy && touched.strategy ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.strategy}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">
                                                                                    <span style={{ "color": "#f00" }}></span> Spread </label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class={"form-control"} value={spread} name='spread' id="autoSizingInputQuanity" onChange={handleChangeOpenInput} placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">
                                                                                    Leverage </label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class={"form-control"} name='leverage' value={leverage} onChange={handleChangeOpenInput} id="autoSizingInputQuanity" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class='col-12 col-md-3'>
                                                                    <label for="portcurrency" class="col-sm-12 col-form-label">Portfolio  Currency </label>
                                                                    <div class="col-sm-12 ms-auto caption2" >
                                                                        <Field name='portfoliocurrency' class={"form-control"} type="text" value={`${data.currency}  `} autoComplete="off" placeholder="" />


                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-12 col-md-3'>
                                                                    <label htmlFor="StockCurrency" className="col-sm-12 col-form-label">Stock Currency </label>
                                                                    <div className="col-sm-12 ms-auto caption2">
                                                                        <Field
                                                                            name='stockcurrency'
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            // value={`${stockCountrySymbol || ''} ${stockCountryCode || ''}`}
                                                                            // onBlur={hideOnBlur}
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                <div class='col-12 col-md-3'>
                                                                    <label for="excahngerate" class="col-sm-12 col-form-label">Exchange Rate <span className='symb'>  {exchangeforexsymbol?.CurrencyFrom && exchangeforexsymbol?.CurrencyTo ? `${exchangeforexsymbol.CurrencyFrom} / ${exchangeforexsymbol.CurrencyTo}` : ''}</span></label>
                                                                    <div class="col-sm-12 ms-auto caption2" >
                                                                        <div class="icon">

                                                                            <div class="input-group mb-3">
                                                                                <input name='exchangerate' type="text" class={"form-control"} value={exchangerate} autoComplete="off" placeholder="1" />
                                                                                <span class="input-group-text" id="basic-addon1" onClick={handleRefresh}>  {loading ? <div className="spinner"></div> : <IoReload />}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class='col-12 col-md-3'>
                                                                    <label for="Valueinshsares" class="col-sm-12 col-form-label">Value {data.currency} </label>
                                                                    <div class="col-sm-12 ms-auto caption2" >
                                                                        <div class="icon">

                                                                            <div class="input-group mb-3">
                                                                                <input type="text" disabled class={"form-control"} name='amountvalue' value={amountvalue} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                {/* <span class="input-group-text" id="basic-addon1" onClick={handleRefresh}>  {loading ? <div className="spinner"></div> : <IoReload />}</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>





                                                            </div>

                                                        </div>
                                                        <div class=" about-content-bx top-know-price-bx top-know-price-bx-1" style={{ marginBottom: 25, marginTop: 20 }}>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="know-prd-bx">
                                                                        <h6>ASK</h6>
                                                                        <span class={checkAskPrLo == 'up' ? "know-price-1 colorGreen" : checkAskPrLo == 'down' ? "know-price-1 colorRed" : "know-price-1"}>           {askprice}          {checkAskPrLo == 'up' ? <BsFillArrowUpRightCircleFill /> : checkAskPrLo == 'down' ? <BsArrowDownRightCircleFill /> : ""}</span>
                                                                        {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="know-prd-bx border-0">
                                                                        <h6>BID</h6>
                                                                        <span class={checkBidPrLo == 'up' ? "know-price-1 colorGreen" : checkBidPrLo == 'down' ? "know-price-1 colorRed" : "know-price-1"}>         {bidprice}    {checkBidPrLo == 'up' ? <BsFillArrowUpRightCircleFill /> : checkBidPrLo == 'down' ? <BsArrowDownRightCircleFill /> : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div class="col-lg-12">
                                                                <div class="postion-min-dta postion-frm focusCss mt-1 tread-vol-bx border-redious-top-left">
                                                                    <div class="row">
                                                                        <div class="col-8  border-bottom">
                                                                            <ul class="nav nav-pills " id="pills-tab" role="tablist">
                                                                                <li class="nav-item" role="presentation">
                                                                                    <button class={short == 'buy' || short == 'long' ? "nav-link active active-buy" : "nav-link"} onClick={() => handleChangeTradeType('long')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#buyTradeMain" type="button" role="tab" aria-controls="buyTrade" aria-selected="true">Buy</button>
                                                                                </li>
                                                                                <li class="nav-item " role="presentation">
                                                                                    <button class={short == 'sell' || short == 'short' ? "nav-link active active-sell" : "nav-link"} onClick={() => handleChangeTradeType('short')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#sellTradeMain" type="button" role="tab" aria-controls="sellTrade" aria-selected="false">Sell</button>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                        <div class="col-4">
                                                                            <select type="text" value={checkStop} class="w-100 custom-select select-limit input_border" onChange={handleChangeStopLimit} id="formGroupExampleInput" placeholder="0" autocomplete="off">
                                                                                <option value={'0'}>Limit</option>
                                                                                <option value={'1'}>Stop Limit</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="tab-content" id="pills-tabContent">
                                                                        <div class={short == 'buy' || short == 'long' ? "tab-pane fade show active" : "tab-pane fade"} id="buyTradeMain" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                            <div class="row">
                                                                                <div class="">
                                                                                    <div class=" mt-4">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={"know-commper-bx-min d-flex "}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">At Price  <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" name='entryPrice' class="form-control p-0 w-75 height-39 " value={entryPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />

                                                                                                </div>
                                                                                                {errors.entryPrice && touched.entryPrice ? (
                                                                                                    <div className='color-msg-err'>{errors.entryPrice}</div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={"know-commper-bx-min d-flex disabled-input"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Value {data.currency} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" disabled class="form-control p-0 w-50 height-39" name='amountvalue' value={amountvalue} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {/* {errors.amountvalue && touched.amountvalue ? (
                                                                                                    <div className='color-msg-err'>{errors.amountvalue}</div>
                                                                                                ) : null} */}
                                                                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                                            </div>


                                                                                        </div>
                                                                                        <div class="row ">
                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={checkInput == true && quantity == '' ? "know-commper-bx-min d-flex input-err-color" : "know-commper-bx-min d-flex"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Lot <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" class="form-control w-75 height-39 " name='lotValue' value={lotValue} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {errors.lotValue && touched.lotValue ? (
                                                                                                    <div className='color-msg-err'>{errors.lotValue}</div>
                                                                                                ) : null}
                                                                                            </div>

                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={"know-commper-bx-min d-flex"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Amount Lot <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" class="form-control w-75 height-39 " name='AmountLot' value={Lotquantity} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {errors.Lotquantity && touched.Lotquantity ? (
                                                                                                    <div className='color-msg-err'>{errors.Lotquantity}</div>
                                                                                                ) : null}
                                                                                            </div>


                                                                                        </div>
                                                                                        <div className='row'>
                                                                                            {
                                                                                                checkStop == '1' &&
                                                                                                <div class="col-lg-6 mb-4">

                                                                                                    <div class={checkInput == true && stopLossPrice == '' ? "know-commper-bx-min d-flex input-err-color" : "know-commper-bx-min d-flex"}>
                                                                                                        <div>
                                                                                                            <label for="formGroupExampleInput" class="form-label label_grey_input">Stop Price <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                            <span className='border-2px'></span>
                                                                                                        </div>
                                                                                                        <input type="text" class="form-control w-75 height-39" onBlur={validationStoploss} name='stopLossPrice' value={stopLossPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                    </div>
                                                                                                    {errors.stopLossPrice && touched.stopLossPrice ? (
                                                                                                        <div className='color-msg-err'>{errors.stopLossPrice}</div>
                                                                                                    ) : null}


                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <button class="btn col-lg-12 buyButton" type='submit' disabled={loading}>Trade Buy</button>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                        <div class={short == 'sell' || short == 'short' ? "tab-pane fade show active" : "tab-pane fade"} id="sellTradeMain" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                            <div class="row">
                                                                                <div class="">
                                                                                    <div class=" mt-4">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={checkInput == true && TransFee == '' && TransFee == undefined ? "know-commper-bx-min d-flex input-err-color  " : "know-commper-bx-min d-flex "}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">At Price <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" name='entryPrice' class="form-control w-75 height-39 " value={entryPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {errors.entryPrice && touched.entryPrice ? (
                                                                                                    <div className='color-msg-err'>{errors.entryPrice}</div>
                                                                                                ) : null}
                                                                                            </div>

                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={"know-commper-bx-min d-flex disabled-input"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Value {data.currency} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" disabled class="form-control p-0 w-50 height-39" name='amountvalue' value={amountvalue} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>

                                                                                                {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row">

                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={checkInput == true && quantity == '' ? "know-commper-bx-min d-flex input-err-color" : "know-commper-bx-min d-flex"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Lot <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" class="form-control w-75 height-39" name='lotValue' value={lotValue} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {errors.lotValue && touched.lotValue ? (
                                                                                                    <div className='color-msg-err'>{errors.lotValue}</div>
                                                                                                ) : null}
                                                                                            </div>

                                                                                            <div class="col-lg-6 mb-4">
                                                                                                <div class={checkInput == true && quantity == '' ? "know-commper-bx-min d-flex input-err-color" : "know-commper-bx-min d-flex"}>
                                                                                                    <div>
                                                                                                        <label for="formGroupExampleInput" class="form-label label_grey_input">Amount Lot <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                        <span className='border-2px'></span>
                                                                                                    </div>
                                                                                                    <input type="text" class="form-control w-75 height-39" name='AmountLot' value={Lotquantity} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                </div>
                                                                                                {errors.Lotquantity && touched.Lotquantity ? (
                                                                                                    <div className='color-msg-err'>{errors.Lotquantity}</div>
                                                                                                ) : null}
                                                                                            </div>


                                                                                            {
                                                                                                checkStop == '1' &&
                                                                                                <div class="col-lg-6 mb-4">

                                                                                                    <div class={checkInput == true && stopLossPrice == '' ? "know-commper-bx-min d-flex input-err-color" : "know-commper-bx-min d-flex"}>
                                                                                                        <div>
                                                                                                            <label for="formGroupExampleInput" class="form-label label_grey_input">Stop Price <span style={{ "color": "#f00" }}>*</span></label>
                                                                                                            <span className='border-2px'></span>
                                                                                                        </div>
                                                                                                        <input type="text" class="form-control w-75 height-39" onBlur={validationStoploss} name='stopLossPrice' value={stopLossPrice} onChange={handleChengeTradeInput} id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                                                    </div>
                                                                                                    {errors.stopLossPrice && touched.stopLossPrice ? (
                                                                                                        <div className='color-msg-err'>{errors.stopLossPrice}</div>
                                                                                                    ) : null}

                                                                                                    {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>




                                                                                    <button class="btn col-lg-12 sellButton" type='submit' disabled={loading} >Trade Sell</button>


                                                                                </div>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* Execute when price hits */}

                                                        {/* <div className='row'>

                                                            <div className='mt-3'>
                                                                <div className=' mt-2' >

                                                                    <label>
                                                                        <input type='checkbox' name="ExecuteCheckBox" checked={showExecutePrice} onChange={ShowCheckboxData} />
                                                                        <label style={{ margin: 10 }}> Execute when Price Hits </label>
                                                                    </label>

                                                                    <span className='info-icon' title='Select a target price to execute a BUY or SELL position. you can opt to cancel this order on a pre-set date too.' > &#x24D8; </span>


                                                                    <div className='col-md-12'>

                                                                        {showExecutePrice && (
                                                                            <div className='row mt-3'  >
                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}>Price</label>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <input type='text' name='executeprice' onKeyPress={handleKeyPress} value={priceValue} onChange={handlePriceInputChange} style={{
                                                                                            border: 'none',
                                                                                            borderBottom: '1px solid black',
                                                                                            outline: 'none',
                                                                                        }} />




                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}> 0.16%</label>
                                                                                    <div className='signs' >
                                                                                        <span data-action='executeincrease' onClick={handleIncreaseCount} style={{ cursor: 'pointer', marginRight: 24 }}>+</span>
                                                                                        <span onClick={decreaseExecuteCount} style={{ cursor: 'pointer' }}>-</span>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='col-md-1'>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        checked={isCancelOnDateChecked}
                                                                                        onChange={(event) => setIsCancelOnDateChecked(event.target.checked)}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-md-3'>

                                                                                    <label style={{ fontSize: 13 }}>Cancel on Date</label>

                                                                                    <div>
                                                                                        <input
                                                                                            type='date'
                                                                                            value={DateValue}
                                                                                            onChange={handleDateInputChange}


                                                                                            style={{
                                                                                                border: 'none',
                                                                                                borderBottom: '1px solid black',
                                                                                                outline: 'none',
                                                                                            }}
                                                                                            disabled={!isCancelOnDateChecked} // Disable the input when the checkbox is unchecked
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* Take Profit */}
                                                        {/* <div className='row'>
                                                            <div className='col-sm-12'>
                                                                <div className='mt-3'>
                                                                    <div className='position-bx-min mt-2' >
                                                                        <label>
                                                                            <input type='checkbox' name='ProfitCheckBox' checked={showData} onChange={ShowCheckboxData} />
                                                                            <label style={{ margin: 10 }}>  Take profit </label>
                                                                        </label>
                                                                        <span className='info-icon' title='Pre-set a Take Profit  to book potential profits when the market is moving in your favor.' > &#x24D8; </span>
                                                                        {showData && (
                                                                            <div className='row mt-3'  >
                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}>Price</label>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <input type='text' name='profitprice' onKeyPress={handleKeyPress} value={profitprice} onChange={handlePriceInputChange} style={{
                                                                                            border: 'none',
                                                                                            borderBottom: '1px solid black',
                                                                                            outline: 'none',
                                                                                        }} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='minimum' style={{ fontSize: 13 }}>Min: 323235 </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label style={{ fontSize: 13 }}> 0.16%</label>
                                                                                    <div className='signs' >
                                                                                        <span data-action='profitincrease' onClick={handleIncreaseCount} style={{ cursor: 'pointer', marginRight: 24 }}>+</span>
                                                                                        <span onClick={decreaseProfitCount} style={{ cursor: 'pointer' }}>-</span>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}>Possible Profit $ </label>
                                                                                    <div>
                                                                                        <input type='text' value={value} onChange={handleInputChange} style={{
                                                                                            border: 'none',
                                                                                            borderBottom: '1px solid black',
                                                                                            outline: 'none',
                                                                                        }} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='minimum' style={{ fontSize: 13 }}>Min: 323235 </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label></label>
                                                                                    <div className='signs' >
                                                                                        <span onClick={() => console.log("Plus clicked")} style={{ cursor: 'pointer', marginRight: 24 }}>+</span>
                                                                                        <span onClick={() => console.log("Minus clicked")} style={{ cursor: 'pointer' }}>-</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}


                                                        {/* Stop Loss */}
                                                        {/* <div className='row'>
                                                            <div className='col-sm-12'>
                                                                <div className='mt-3'>
                                                                    <div className='position-bx-min mt-2' >
                                                                        <label>
                                                                            <input type='checkbox' name="LossCheckBox" checked={showStopLoss} onChange={ShowCheckboxData} />
                                                                            <label style={{ margin: 10 }}> Stop Loss </label>
                                                                        </label>
                                                                        <span className='info-icon' title='Pre-set a Stop Loss orderto minimize losses  when the market is moving against you.' > &#x24D8; </span>
                                                                        {showStopLoss && (
                                                                            <div className='row mt-3'  >
                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}>Price</label>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <input type='text' name='stoplossprice' value={lossprice} onChange={handlePriceInputChange} style={{
                                                                                            border: 'none',
                                                                                            borderBottom: '1px solid black',
                                                                                            outline: 'none',
                                                                                        }} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='minimum' style={{ fontSize: 13 }}>Min: 323235 </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label style={{ fontSize: 13 }}> 0.16% </label>
                                                                                    <div className='signs' >
                                                                                        <span data-action='lossincrease' onClick={handleIncreaseCount} style={{ cursor: 'pointer', marginRight: 24 }}>+</span>
                                                                                        <span onClick={decreaseLossCount} style={{ cursor: 'pointer' }}>-</span>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='col-md-2'>
                                                                                    <label style={{ fontSize: 13 }}>Possible Profit $ </label>
                                                                                    <div>
                                                                                        <input type='text' value={possibleprice} onChange={handleInputpossiblepriceChange} style={{
                                                                                            border: 'none',
                                                                                            borderBottom: '1px solid black',
                                                                                            outline: 'none',
                                                                                        }} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='minimum' style={{ fontSize: 13 }}>Min: 323235 </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label></label>
                                                                                    <div className='signs' >
                                                                                        <span onClick={increasePossibleCount} style={{ cursor: 'pointer', marginRight: 24 }}>+</span>
                                                                                        <span onClick={decreasePossibleCount} style={{ cursor: 'pointer' }}>-</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="d-grid gap-2 d-sm-block mt-3 d-md-flex">
                                                            {
                                                                showSaveButton ?
                                                                    <button type='button' className='btn loading-button position-relative d-flex'>
                                                                        <div class="dot1"> </div>
                                                                        <div class="dot2"></div>
                                                                        <div class="dot3"></div>
                                                                    </button> :

                                                                    <button class="btn " type="submit">Save</button>
                                                            }
                                                            <button class="btn ms-2" type="button" data-bs-dismiss="modal" id="openTradeModelClose" aria-label="Close" onClick={handleRemoveOpenData}>Cancel</button>
                                                        </div> */}

                                                        {/* <!-- <div class="col-12"> --> */}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            {/* <!-- trs det --> */}
                        </div>
                    </div>
                </div>
            </div >
            <CummissionDetails cummissionData={cummissionData} />
        </>
    )
}

export default OpenTrade

import React from 'react'

const NumberConverter = (number) => {
    var SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(2) + suffix;
}
export default NumberConverter
import React, { useEffect, useState } from 'react'
import { getWatchList, removeWatchList } from '../../../services/TradeService'
import moment from 'moment/moment'
import TradeModel from '../../Trade/Model/TradeModel'
import Pagination from '../../commonComponent/Pagination'

const WatchList = ({ userType }) => {
    const [watchList, setWatchList] = useState([])
    const [watchListRun, setWatchListRun] = useState(true)
    const [tradeCode, setTradeCode] = useState('')
    const [paginationWatch, setPaginationWatch] = useState({ currentPageWatch: 1, pageNumberWatch: 1, pageSizeWatch: 10, totalPagesWatch: 0 })
    const { pageNumberWatch, pageSizeWatch, currentPageWatch, totalPagesWatch } = paginationWatch
    useEffect(() => {
        let data = {
            id: userType.userId,
            pageNumber: currentPageWatch,
            pageSize: pageSizeWatch
        }
        if (data.id !== undefined) {
            try {
                getWatchList(data).then((res) => {
                    if (res?.status === 200) {
                        setWatchList(res.data.data)
                        setWatchListRun(false)
                        setPaginationWatch({ ...paginationWatch, totalPagesWatch: res.data.totalPages })
                    }
                })
            } catch (error) {

            }
        }
    }, [watchListRun === true, userType])
    
    const handleRemoveWatchList = (id) => {
        try {
            removeWatchList(id).then((res) => {
                if (res?.status === 200) {
                    setWatchListRun(true)
                }
            })
        } catch (error) {

        }
    }

    const handlePageChangeWatch = (newPage) => {
        setPaginationWatch({ ...paginationWatch, currentPageWatch: currentPageWatch + newPage })
        // Perform data fetching or other actions when page changes
    };

    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="watchList" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Watchlist</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>
                        <div className="modal-body blackout-bx">
                            {/* <!-------modeal-----> */}
                            <div class="row">
                                {/* <section>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="postion-bx-min mt-4">
                                                    <span class="frm-hd-t">United State</span>
                                                    <div class="postion-frm mt-2 table-input1" style={{ "border-top": "1px solid #cbd0dd" }}>
                                                        <span class="padding-ds">Pending Positions</span>
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered mt-3 text-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Date</th>
                                                                        <th scope="col">Code</th>
                                                                        <th scope="col">Strategy</th>
                                                                        <th scope="col">Direction</th>
                                                                        <th scope="col">Entry</th>
                                                                        <th scope="col">Stop</th>
                                                                        <th scope="col">Target </th>
                                                                        <th scope="col">Actions</th>
                                                                        <th scope="col">Closed</th>
                                                                        <th scope="col"> Pending Position</th>
                                                                        <th scope="col">Edit</th>
                                                                        <th scope="col">delete</th>
                                                                        <th scope="col">Chart</th>
                                                                        <th scope="col">Watchlist</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20 March </th>
                                                                        <td>AAPL.O</td>
                                                                        <td>test</td>
                                                                        <td>Long</td>
                                                                        <td>139.93</td>
                                                                        <td>135</td>
                                                                        <td>145</td>
                                                                        <td>test</td>
                                                                        <td>....</td>
                                                                        <td><button class="btn padding-btn-dtd"> <img src="assets/image/wall-clock.png" class="img-fluid" alt='' /> PENDING</button> </td>
                                                                        <td><button class="btn padding-btn-dtd edit-btn"> <img src="assets/image/edit.png" class="img-fluid" alt='' /> Edit</button> </td>
                                                                        <td><button class="btn padding-btn-dtd delete-btn"> <img src="assets/image/delete.png" class="img-fluid" alt='' /> Delete</button> </td>
                                                                        <td><button class="btn padding-btn-dtd open-btn"> <img src="assets/image/folder.png" class="img-fluid" alt='' /> Open</button> </td>
                                                                        <td><button class="btn padding-btn-dtd watch"> <img src="assets/image/watch.png" class="img-fluid" alt='' /> WATCHLIST</button> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">20 March </th>
                                                                        <td>AAPL.O</td>
                                                                        <td>test</td>
                                                                        <td>Long</td>
                                                                        <td>139.93</td>
                                                                        <td>135</td>
                                                                        <td>145</td>
                                                                        <td>test</td>
                                                                        <td>....</td>
                                                                        <td><button class="btn padding-btn-dtd"> <img src="assets/image/wall-clock.png" class="img-fluid" alt='' /> PENDING</button> </td>
                                                                        <td><button class="btn padding-btn-dtd edit-btn"> <img src="assets/image/edit.png" class="img-fluid" alt='' /> Edit</button> </td>
                                                                        <td><button class="btn padding-btn-dtd delete-btn"> <img src="assets/image/delete.png" class="img-fluid" alt='' /> Delete</button> </td>
                                                                        <td><button class="btn padding-btn-dtd open-btn"> <img src="assets/image/folder.png" class="img-fluid" alt='' />  Opens</button> </td>
                                                                        <td><button class="btn padding-btn-dtd watch"> <img src="assets/image/watch.png" class="img-fluid" alt='' /> WATCHLIST</button> </td>
                                                                    </tr>

                                                                </tbody>

                                                            </table>
                                                        </div>
                                                        <span class="padding-ds">Open Positions</span>
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered mt-3 text-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Date</th>
                                                                        <th scope="col">Code</th>
                                                                        <th scope="col">Strategy</th>
                                                                        <th scope="col">Direction</th>
                                                                        <th scope="col">Entry</th>
                                                                        <th scope="col">Stop</th>
                                                                        <th scope="col">Target </th>
                                                                        <th scope="col">Actions</th>
                                                                        <th scope="col">Closed</th>
                                                                        <th scope="col"> Pending Position</th>
                                                                        <th scope="col">Edit</th>
                                                                        <th scope="col">delete</th>
                                                                        <th scope="col">Chart</th>
                                                                        <th scope="col">Watchlist</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20 March </th>
                                                                        <td>AAPL.O</td>
                                                                        <td>test</td>
                                                                        <td>Long</td>
                                                                        <td>139.93</td>
                                                                        <td>135</td>
                                                                        <td>145</td>
                                                                        <td>test</td>
                                                                        <td>....</td>
                                                                        <td><button class="btn padding-btn-dtd"> <img src="assets/image/wall-clock.png" class="img-fluid" alt='' /> PENDING</button> </td>
                                                                        <td><button class="btn padding-btn-dtd edit-btn"> <img src="assets/image/edit.png" class="img-fluid" alt='' /> Edit</button> </td>
                                                                        <td><button class="btn padding-btn-dtd delete-btn"> <img src="assets/image/delete.png" class="img-fluid" alt='' /> Delete</button> </td>
                                                                        <td><button class="btn padding-btn-dtd open-btn"> <img src="assets/image/folder.png" class="img-fluid" alt='' /> Open</button> </td>
                                                                        <td><button class="btn padding-btn-dtd watch"> <img src="assets/image/watch.png" class="img-fluid" alt='' /> WATCHLIST</button> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">20 March </th>
                                                                        <td>AAPL.O</td>
                                                                        <td>test</td>
                                                                        <td>Long</td>
                                                                        <td>139.93</td>
                                                                        <td>135</td>
                                                                        <td>145</td>
                                                                        <td>test</td>
                                                                        <td>....</td>
                                                                        <td><button class="btn padding-btn-dtd"> <img src="assets/image/wall-clock.png" class="img-fluid" alt='' /> PENDING</button> </td>
                                                                        <td><button class="btn padding-btn-dtd edit-btn"> <img src="assets/image/edit.png" class="img-fluid" alt='' /> Edit</button> </td>
                                                                        <td><button class="btn padding-btn-dtd delete-btn"> <img src="assets/image/delete.png" class="img-fluid" alt='' /> Delete</button> </td>
                                                                        <td><button class="btn padding-btn-dtd open-btn"> <img src="assets/image/folder.png" class="img-fluid" alt='' />  Opens</button> </td>
                                                                        <td><button class="btn padding-btn-dtd watch"> <img src="assets/image/watch.png" class="img-fluid" alt='' /> WATCHLIST</button> </td>
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}
                                <section>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="postion-bx-min">
                                                    <span class="frm-hd-t">Watchlist</span>
                                                    <div class="postion-frm mt-1 table-input1-watchlist border-top-1 table-input1" style={{ "border-top": "1px solid #cbd0dd;" }}>
                                                        {/* <span class="padding-ds">Watchlist</span> */}
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered mt-3 text-center">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className='color-grey'>Date</th>
                                                                        <th scope="col" className='color-grey'>stock Name</th>
                                                                        <th scope="col" className='color-grey'>stock Code</th>

                                                                        <th scope="col" className='color-grey'>Chart</th>
                                                                        <th scope="col" className='color-grey'>Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        watchList.map((val, ind) => {
                                                                            return (
                                                                                <tr key={ind}>
                                                                                    <th scope="row">{moment(val.createdDate).format('DD MMM YYYY')} </th>
                                                                                    <td>{val.stockName}</td>
                                                                                    <td>{val.stockCode}</td>
                                                                                    <td><button class="btn padding-btn-dtd open-btn w-100" title='Open' data-bs-toggle="modal" data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td>
                                                                                    <td><button class="btn padding-btn-dtd delete-btn w-100" title='Remove' onClick={() => handleRemoveWatchList(val.watchListId)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <Pagination
                                                                currentPage={currentPageWatch}
                                                                totalPages={totalPagesWatch}
                                                                onPageChange={handlePageChangeWatch}
                                                                setPagination={setPaginationWatch}
                                                                pageNumber={pageNumberWatch}
                                                                pageSize={pageSizeWatch}
                                                                pagination={paginationWatch}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {/* <!-------modeal-----> */}
                    </div>
                </div>
            </div>
            <TradeModel tradeCode={tradeCode} />
        </>
    )
}

export default WatchList
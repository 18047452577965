import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { addCummissionAPI, addHelpVideo, editHelpVideo } from '../../../services/ProviderServices';
import { toast } from 'react-toastify';
import { FaRegFileVideo, FaCloudUploadAlt } from "react-icons/fa";

let validationSchemaVideo = Yup.object().shape({
    moduleNeme: Yup.string().required('Module name is required'),

});
const AddEditVideo = ({addUpdateVideoSelect,inputVideo,setInputVideo, getHelpVideo}) => {
    
    const { moduleNeme, description, videoFile, helpNeme, userId, reRenderV } = inputVideo
    const [buttonDisable, setButtonDisable] = useState(false)
    const [dragging, setDragging] = useState(false);
    
    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };
    useEffect(()=>{
        
        let userDetails=JSON.parse(localStorage.getItem('userDetails'))
      
        setInputVideo({...inputVideo, userId:userDetails?.userId})
    },[])
    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setInputVideo({ ...inputVideo, videoFile: droppedFile });
        }
    };
    const handleAddEditVideo = (value) => {
        // debugger
        setButtonDisable(true);

        let closeBtn=document.getElementById('closeAddEditVideo')
        if (addUpdateVideoSelect === 'add') {
            try {
              
                addHelpVideo(value).then((res) => {
                    if (res?.status === 200) {
                        getHelpVideo()
                        closeBtn.click()
                    }
                }).finally(() => {
                    // Enable the button when the asynchronous operation finishes
                    setButtonDisable(false);
                });
            } catch (error) {
                setButtonDisable(false);
            }
        } else {
            try {
              
                editHelpVideo(value,inputVideo.tradingHelpId).then((res) => {
                    if (res?.status === 200) {
                        getHelpVideo()
                        closeBtn.click()
                    }
                })
            } catch (error) {
            }
        }
    }
    const handleAddVideo = () => {
        
        // UserId:'', ModuleNeme: '', HelpNeme: '', Description: '', videoFile: ''
        const formData = new FormData();
        formData.append('UserId',userId);
        formData.append('ModuleNeme', moduleNeme);
        formData.append('HelpNeme', helpNeme);
        formData.append('Description', description);
        formData.append('file', videoFile);
        const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;

            if (videoFile) {
                handleAddEditVideo(formData)
            } else if(urlRegex.test(helpNeme.toLowerCase()) || inputVideo.tradingHelpId) {
                handleAddEditVideo(formData)
            }else{
                toast.error('Please upload a video file or provide a valid video URL.')
            }
    }
    const handleBlurVideoInput = () => {

    }
    const handleRemoveVideoInputData = () => {
        const input = document.getElementById('videoInput');
        setInputVideo({ UserId:'', moduleNeme: '', helpNeme: '', description: '', videoFile: '',reRenderV:reRenderV+1 });
        input.value = ''
    }
    const handleRemoveSelectedVideo = () => {
        const input = document.getElementById('videoInput');
        setInputVideo({ ...inputVideo, videoFile: '' });
        input.value = ''
    }
    const handleInputVideoChange = (e) => {
        
        if (e.target.name == 'videoFile') {
            const input = document.getElementById('videoInput');
            // const validationMessage = document.getElementById('validationMessage');
            if (e.target.files.length > 0) {
                const videoFiles = e.target.files[0];
                const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg'];

                if (allowedTypes.includes(videoFiles.type)) {
                    // setVideoErrMsg('Please select a valid video file (MP4, WebM, or Ogg).')
                    //   validationMessage.textContent = `Selected video: ${videoFiles.name}`;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
                    const maxSizeInBytes = 50 * 1024 * 1024; // 50 MB

                    if (videoFiles.size <= maxSizeInBytes) {
                        if (urlRegex.test(helpNeme) || helpNeme=='') {
                            setInputVideo({ ...inputVideo, videoFile: videoFiles, helpNeme: videoFiles.name });
                        } else {
                            setInputVideo({ ...inputVideo, videoFile: videoFiles });
                        }
                    } else {
                        setInputVideo({ ...inputVideo, videoFile: '' });
                        input.value = ''
                        toast.error('Please select a video file with a size of 50 MB or less.')
                    }
                } else {
                    //   validationMessage.textContent = 'Please select a valid video file (MP4, WebM, or Ogg).';
                    setInputVideo({ ...inputVideo, videoFile: '' });
                    input.value = ''
                    toast.error('Please select a valid video file (MP4, WebM, or Ogg).')
                }
            } else {

            }
        } else if (e.target.name == 'helpNeme') {
            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

            if (urlRegex.test(e.target.value)) {
                setInputVideo({ ...inputVideo, [e.target.name]: e.target.value, videoFile: '' });
            } else {
                setInputVideo({ ...inputVideo, [e.target.name]: e.target.value });
            }
        } else {
            setInputVideo({ ...inputVideo, [e.target.name]: e.target.value });
        }
    }
   
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="addHelpVideo" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-md  modal-dialog-centered" id='upload_Video' >
                <div className="modal-content  provider-min-md">
                    <div className="modal-header border-bottom-0">
                        <h5 className="modal-title h4" id="exampleModalLgLabel">Upload Video</h5>
                        <button type="button" className="btn-close btn-close-provider" id='closeAddEditVideo' data-bs-dismiss="modal" onClick={handleRemoveVideoInputData} aria-label="Close">×</button>
                    </div>

                    <div className="modal-body blackout-bx">
                        <Formik
                            initialValues={inputVideo}
                            enableReinitialize
                            validationSchema={validationSchemaVideo}
                            onSubmit={(value) => {
                                handleAddVideo(value)
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form className='row p-3'>
                                    {/* <div class="col-sm-12"> */}
                                    <div class="col-12 col-sm-6">
                                        <label>Module Name <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" class="form-control input_font-Size" onBlur={handleBlurVideoInput} name='moduleNeme' value={moduleNeme} onChange={handleInputVideoChange} required="" id="autoSizingInput" placeholder="Name" />
                                        {errors.moduleNeme && touched.moduleNeme ? (
                                            <div class='color-msg-err'>{errors.moduleNeme}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label>File URL</label>
                                        <Field type="text" class="form-control input_font-Size" onBlur={handleBlurVideoInput} name='helpNeme' value={helpNeme} onChange={handleInputVideoChange} required="" id="autoSizingInput" placeholder="HelpNeme / File Name" />
                                        {errors.videoFile && touched.videoFile ? (
                                            <div className='color-msg-err'>{errors.videoFile}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-12 col-sm-12 ms-auto mt-3">
                                        <label>Description</label>
                                        <Field type="text" as="textarea" class="form-control input_font-Size" name='description' value={description} onChange={handleInputVideoChange} id="autoSizingInput" placeholder="" />
                                    </div>
                                    <div class="col-12 col-sm-12 ms-auto mt-3">
                                        <div class="upload-files-container"
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}
                                        >
                                            <div class="drag-file-area">
                                                <span class="material-icons-outlined upload-icon"> <FaCloudUploadAlt /> </span>
                                                <h6 class="dynamic-message"> Drag & drop any file here </h6>
                                                <label class="label"><span class="browse-files"> <input type="file" id='videoInput' name='videoFile' class="default-file-input" onChange={handleInputVideoChange} /> <span class="browse-files-text">browse file</span></span> </label>
                                                <span style={{ 'color': '#0a2865', }}><small>mp4, webm, ogg files up to 50MB allowed</small></span>
                                            </div>
                                            {
                                                videoFile &&

                                                <div class="file-block d-flex">
                                                    <div class="file-info"> <FaRegFileVideo /> <span class="file-name"> </span> | <span class="file-size">{videoFile.name}  </span>  </div>
                                                    <span class="material-icons remove-file-icon" onClick={handleRemoveSelectedVideo} ><i class="bi bi-trash3-fill"></i></span>
                                                </div>
                                            }
                                        </div>
                                    </div>




                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' disabled={buttonDisable} > Save </button>
                                        </div>
                                        <div className='col-md-6'>
                                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-Cummission' type='button' onClick={handleRemoveVideoInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEditVideo
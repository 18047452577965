import React, { useEffect, useRef, useState } from 'react'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'
import SideBar from '../components/commonComponent/SideBar'
import Hero from '../components/Home/Hero'
// import CreateTrade from '../components/Home/Models/CreateTrade'
import Summary from '../components/Home/Models/Summary'
import TradeCalculater from '../components/Home/Models/TradeCalculater'
import WatchList from '../components/Home/Models/WatchList'
import PortfolioTable from '../components/Home/PortfolioTable'
import { getPortfoloio } from '../services/PortfolioService'
import { GetCurrentBalabce, getProviders } from '../services/ProviderServices'
import PortFolio from '../components/Home/Models/PortFolio'
import CreateStrategy from '../components/Home/Models/Strategy'
import ManageAccount from '../components/Home/Models/ManageAccount'
import OpenTrade from '../components/Home/Models/OpenTrade'
import { useLocation } from 'react-router-dom'
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import Feedback from '../components/Home/feedback'
import axios from 'axios'
import { getCountryListAPI } from '../services/commonServices'
import MarketOverview from '../components/Home/Models/MarketOverview'
import VideoPlayList from '../components/Home/Models/VideoPlayList'
import Loader from '../components/commonComponent/Loader'
import Records from './Records'
// import { getStockDetails } from '../services/TradeService'


const Home = () => {
    const [portfololioList, setPortfolioList] = useState([])
    const [providerList, setProviderList] = useState([])
    const [runPortFolioAPI, setRunPortFolioAPI] = useState(true)
    const [openTradeRun, setOpenTradeRun] = useState(false)
    const [selectedUserData, setSelectedUserData] = useState([])
    const [openTradeList, setOpenTradeList] = useState([])
    const [calculatedData, setCalculatedData] = useState()
    const [getOpenToggleId, setGetOpenToggleId] = useState()
    const [userType, setUserType] = useState('')
    const [runbalanceTranAPI, setRunBalancsTranAPI] = useState(true)
    const [runAgainDashBoard, setRunAgainDashBoard] = useState(1)
    const [countryList,setCountryList]=useState([])
    const [playvideo, setPlayVideo] = useState(false)
    const [loading, setLoading] = useState(false)
    // help mode start
    const [helpSetting, setHelpSetting] = useState({
        run: false,
        steps: [
            {
                content: <><h4>Let's begin our journey!</h4>
                    <div className='d-flex align-self-center gap-2 align-items-baseline'><span className=' text-white text-center' style={{ backgroundColor: 'rgb(255 128 56)', borderRadius: '50%', width: '23px', height: '23px', padding: '4px', fontSize: '12px' }}>1</span><h6>Start by creating your portfolio</h6></div>
                    <div className='d-flex align-self-center gap-2 align-items-baseline'><span className=' text-white text-center' style={{ backgroundColor: 'rgb(255 128 56)', borderRadius: '50%', width: '25px', height: '23px', padding: '4px', fontSize: '12px' }}>2</span><h6 className='text-start'>Then add demo funds to your portfolio</h6></div>
                </>,
                locale: { next: <span aria-label="skip">Start</span> },
                placement: 'center',
                target: 'body',

            },
            {
                content: <p className='joyrid-content-comon'>Monitor and optimize your investment portfolio's performance.</p>,

                target: '.portfolioStep',
                title: 'Create demo Portfolio',
            },
            {
                content: <p className='joyrid-content-comon'> Easily control and track your trading activities and funds.</p>,

                floaterProps: {
                    disableAnimation: true,
                },
                target: '.accountStep',
                title: 'Add Demo Funds',
            },
            {
                content: <p className='joyrid-content-comon'>Assess potential gains and risks with our intuitive trading tool.</p>,

                placement: 'top',
                target: '.calculatorStep',
                title: 'Calculate Forex  Profit/Loss',
            },
            {
                content: <p className='joyrid-content-comon'>Execute buying and selling orders swiftly and securely.</p>,

                placement: 'top',
                target: '.openTradeStep',
                title: 'Start Trading By Open Forex Trade',
            },
            {
                content: <p className='joyrid-content-comon'>Access expert insights for profitable trading opportunities.</p>,

                placement: 'top',
                target: '.tradeStep',
                locale: { last: 'End' },
                title: 'Forex Trade Recommendations',
            },

        ],
    });
    const { steps, run } = helpSetting
    function getScreenSize() {
        const { innerWidth } = window;
        let breakpoint = 'xs';

        if (innerWidth >= 1024) {
            breakpoint = 'lg';
        } else if (innerWidth >= 768) {
            breakpoint = 'md';
        } else if (innerWidth >= 400) {
            breakpoint = 'sm';
        }

        return breakpoint;
    }
    const [breakpoint, setBreakpoint] = useState(getScreenSize());
    const debounceTimeout = useRef(0);

    const handleResize = useRef(() => {
        clearTimeout(debounceTimeout.current);

        debounceTimeout.current = window.setTimeout(() => {
            setBreakpoint(getScreenSize());
        }, 250);
    });

    useEffect(() => {
        const { current } = handleResize;

        window.addEventListener('resize', current);

        return () => {
            window.removeEventListener('resize', current);
        };
    });
    function logGroup(type, data) {
    }
    const handleJoyrideCallback = (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setHelpSetting({ ...helpSetting, run: false });
        }

        logGroup(type, data);
    };
    const handleClickStart = (event) => {
        // event.preventDefault();

        setHelpSetting({ ...helpSetting, run: true });
    };
    // help mode End
    const location = useLocation()
    let stateData = location.state
    useEffect(() => {
        let nm = localStorage.getItem('userDetails')
        setUserType(JSON.parse(nm))
    }, [])
    setTimeout(() => {
        if (location.state !== null && getOpenToggleId !== null && getOpenToggleId !== undefined) {

            setOpenTradeRun(true)
            setCalculatedData({
                portFolioId: stateData.portfolioId,
                margin: stateData.margin,
                entry: stateData.price,
                stockCode: stateData.stockCode,
                stockName: stateData.stockName,
                quantity: stateData.price / stateData.entry,
                share: '',
                providerId: stateData?.providerId || '',
                risk: '',
                stopLoss: stateData.stopLoss,
                cfd: '',
                target: '',
                cummission: '',
                reward: '',
                rewardPercent: ''
            })
        }
    }, 400)
    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    setCountryList(res.data)
                }
            })
        } catch (error) {

        }
    }, [])
    useEffect(() => {
        if (userType.userId !== undefined && userType?.userId.length > 0) {
            try {
                getPortfoloio(userType?.userId).then((res) => {
                    if (res?.status === 200) {
                        
                        setPortfolioList(res.data)
                        setRunPortFolioAPI(false)
                        if (res.data.length == 0) {
                            setHelpSetting({ ...helpSetting, run: true });
                        }
                    }
                })
            } catch (error) {

            }
        }

    }, [runPortFolioAPI === true, runAgainDashBoard, userType])
    useEffect(() => {
        let data = {
            PageSize: 1000,
            PageNumber: 1
        }
        try {
            getProviders(data).then((res) => {
                if (res?.status === 200) {
                    setProviderList(res.data.data)
                }
            })
        } catch (error) {

        }

    }, [])


    // return of portfolio = final amount -initial portfolio value + withdrawl or deposit amount/initial portfolio value
   
    const getbalanceTrans = () => {
        let data = {
            userId: userType?.userId,
            // portfolioId: portFolioId
        }
        if (data.userId !== undefined) {

            try {
                GetCurrentBalabce(data).then((res) => {
                    if (res?.status === 200) {


                        // setBalanceDetails(res.data)
                    }
                })
            } catch (error) {

            }
            // setBalanceDetails(currentBalanceFun())
        }


    }
   

   


    useEffect(()=>{
        // console.log(selectedUserData ,"selectedUserDataselectedUserData")
        async function fetchPrivateScriptContent() {
            // Replace 'YOUR_ACCESS_TOKEN' with the actual access token obtained from TradingView OAuth 2.0 authentication
            const accessToken = 'QTV5R27CCMW55PHT';
            const privateScriptId = 'XvS9Lamf-Price-Trading-Levels'; // Replace with the ID of your private script
      
            // Construct the URL for the private script API request
            const privateScriptUrl = `https://api.tradingview.com/pine-script/${privateScriptId}`;
      
            const response = await fetch(privateScriptUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
   
            // if (response.ok) {
            //   const { content } = await response.json();
            //   initChart(content);
            // } else {
            //   console.error('Failed to fetch the private script content.');
            // }
          }
        //   fetchPrivateScriptContent()
    },[])

    return (
        <>
            <section>
                <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    run={run}
                    scrollToFirstStep
                    className="joyrid-content-comon"
                    // showProgress
                    showSkipButton
                    steps={steps}
                    disableOverlayClose
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                />
                  {loading && (
                <div className="loader-container">
                    <Loader />
                </div>
            )}
                {/* <button className="btn bt-light fullc-or mt-3 mb-3 ms-auto d-block d-none" type="button" data-bs-toggle="modal" onClick={() => setOpenTradeRun(true)} data-bs-target="#openTrade">Open Trade <span> <i className="bi bi-plus-lg"></i> </span></button> */}
                <div className={`container-fluid ${loading ? 'loading-background' : ''}`}>
                    <div className="row">
                        {/* <SideBar/> */}
                        {/* <!--------ul-----clouse----> */}
                        <div className="col-lg-12 mt-3">
                            {/* <Header/> */}
                            {/* <!--------search-bar----> */}
                            <Hero loading={loading} setLoading={ setLoading}  setPlayVideo ={setPlayVideo}  countryList={countryList} runAgainDashBoard={runAgainDashBoard} helpSetting={helpSetting} handleClickStart={handleClickStart} userType={userType} runbalanceTranAPI={runbalanceTranAPI} setRunBalancsTranAPI={setRunBalancsTranAPI} setOpenTradeList={setOpenTradeList} portfololioList={portfololioList} setSelectedUserData={setSelectedUserData} setOpenTradeRun={setOpenTradeRun} />
                            <PortfolioTable  setLoading={ setLoading} runAgainDashBoard={runAgainDashBoard} userType={userType} setGetOpenToggleId={setGetOpenToggleId} openTradeList={openTradeList} selectedUserData={selectedUserData} setOpenTradeRun={setOpenTradeRun} portfololioList={portfololioList} />
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer/> */}
            {/* <CreateTrade portfololioList={portfololioList} setRunPortFolioAPI={setRunPortFolioAPI} providerList={providerList} /> */}
            <TradeCalculater countryList={countryList} userType={userType} setOpenTradeRun={setOpenTradeRun} calculatedData={calculatedData} setCalculatedData={setCalculatedData} providerList={providerList} portfololioList={portfololioList} setRunPortFolioAPI={setRunPortFolioAPI} />
            <Summary />
            <WatchList userType={userType} />
            <PortFolio runAgainDashBoard={runAgainDashBoard} setRunAgainDashBoard={setRunAgainDashBoard} userType={userType} providerList={providerList} setRunPortFolioAPI={setRunPortFolioAPI} />
            <CreateStrategy userType={userType} />
            <ManageAccount runAgainDashBoard={runAgainDashBoard} setRunAgainDashBoard={setRunAgainDashBoard} setRunBalancsTranAPI={setRunBalancsTranAPI} userType={userType} portfololioList={portfololioList} />
            <OpenTrade runAgainDashBoard={runAgainDashBoard} setRunAgainDashBoard={setRunAgainDashBoard} userType={userType} setCalculatedData={setCalculatedData} calculatedData={calculatedData} openTradeRun={openTradeRun} portfololioList={portfololioList} />
            <Feedback />
            <MarketOverview userType={userType} providerList={providerList} portfololioList={portfololioList}/>
            <VideoPlayList playvideo= {playvideo} setPlayVideo ={setPlayVideo}/>
       
        </>
    )
}

export default Home
import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const APP_KEY = process.env.REACT_APP_APPLICATION_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const getCountryListAPI = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCountry?ApplicationKey=${APP_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
      
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
    
    } catch (error) {
        
    }
}
import React, { useEffect } from 'react'
import SideBar from '../components/commonComponent/SideBar'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'
import { Link } from 'react-router-dom'

const Analysis = () => {
  let width = window.innerWidth;
    // console.log(document.getElementById('tradingview-widget'), document.querySelector(".tradingview-widget-copyright"))
    useEffect(() => {
      if (document.querySelector(".tradingview-widget-copyright")) {
          debugger
          const script = document.createElement('script');
          script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
          script.async = true;
          script.innerHTML = `
        {
          "width": ${width},
          "height": 523,
          "defaultColumn": "overview",
          "defaultScreen": "general",
          "market": "forex",
          "showToolbar": true,
          "colorTheme": "light",
          "locale": "in"
        }
      `;
          // Define a callback function to handle script load errors
          const handleScriptError = () => {
              console.error('Error loading TradingView widget script');
          };

          script.addEventListener('error', handleScriptError);

          const container = document.getElementById('tradingview-widget');
          if (container) {
              container.appendChild(script);
          }

          // Cleanup script and event listener on component unmount
          return () => {
              script.removeEventListener('error', handleScriptError);
              if (container && container.contains(script)) {
                  container.removeChild(script);
              }
              // document.getElementById('tradingview-widget').removeChild(script);
          };
      }
  }, []);


    return (
        <div class="tradingview-widget-container" id="tradingview-widget">
            <div class="tradingview-widget-container__widget"></div>
            <div class="tradingview-widget-copyright">
                <Link href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank">
                    <span class="blue-text">Track all markets on TradingView</span>
                </Link>
            </div>
        </div>
    );
}

export default Analysis
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Loader from '../components/commonComponent/Loader';
import moment from 'moment';
// import CustomPineScript from '../components/commonComponent/CustomPineScript';

const CandleChartView = () => {
    const [chartLoader, setChartLoader] = useState(true)
    const chartRef = useRef()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const n = params.get('tradeCode').split('/');
    let stockCode=n[0]+n[1]
    // let stockCode = location.state?.tradeCode
    let tvScriptLoadingPromise;
    let dateTime = new Date()
    let timeZoneMinut = dateTime.getTimezoneOffset()
    let timeZone = 'UTC' + (timeZoneMinut / 60)
    // let n=moment.tz.zone(timeZone).countries()[0];

    useEffect(
        () => {
            chartRef.current = createWidget;
           
            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');

                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;
                    document.head.appendChild(script);
                });
            }
            tvScriptLoadingPromise.then(() => chartRef.current && chartRef.current());


            function createWidget() {
                if (document.getElementById('tradingview_ac040') && 'TradingView' in window) {
                    setChartLoader(false)
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: stockCode,
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        toolbar_bg: "#f1f3f6",
                        // enable_publishing: true,
                        // withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        enabled_features: ["move_logo_to_main_pane"],
                        // hide_legend: true,
                        details: true,
                        // studies: [
                        //     "ElliottWave@tv-basicstudies",
                        // ],
                        // source: 'https://www.tradingview.com/script/XvS9Lamf-Price-Trading-Levels/',
                      
                        show_popup_button: true,
                        container_id: "tradingview_ac040"

                    });
                    // return () => {
                    //     widget.remove();
                    // };
                }
            }
            // document.querySelector('.tv-header__link').remove();
        }, [stockCode]);
    const handleClose = () => {
        window.close()
    }
    setTimeout(() => {

    }, 3000)
    let height = window.innerHeight;
    document.body.style.height = `${height}px`;
    return (
        <>

            <div className='container-fluid m-0 p-0 position-relative'>
                {/* <Link to={'/'} > */}
                <button type="button" onClick={handleClose} className="btn-close btn-close-provider btn-close-candle" data-bs-dismiss="modal" aria-label="Close">×</button>
                {/* </Link> */}
                {/* <div className="row">
                    <div class="row">
                        <div class="cp-tail-bx cp-tail-bx-asd">
                            <div class="col-sm-12 mt-4 d-flex  md-2">
                                <h1>{stockData[0]?.symbol}</h1>
                                <h5 className=''>{stockData[0]?.close || 0} <span className={increased ? 'green' : 'red'}>{Number(profitLoss ? profitLoss : 0).toFixed(2)} ( {Number(profitPresent ? profitPresent : 0).toFixed(2)}% )</span><span class="id ms-1">{stockData[0]?.priceDate}</span> </h5>
                            </div>
                        </div>
                    </div>
                    <div class="bot-brd "></div>

                </div> */}


                {chartLoader &&
                    <div className='chartLoader'>
                        <Loader />
                    </div>
                }
                <div className='tradingview-widget-container'>
                    <div id='tradingview_ac040' style={{ 'height': `${height}px` }} className='tradingview_5327b-css w-100' />
                    <p ref={chartRef}></p>
                </div>

            </div>
            {/* <div className="tradingview-widget-container" ref={contariner}>
                <div className="tradingview-widget-container__widget"></div>
                <div className="tradingview-widget-copyright"></div>
            </div> */}

        </>
    )
}

export default CandleChartView
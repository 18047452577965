import React, { useEffect, useMemo, useState } from 'react'
import { createPortfoloio, editPortfoloio } from '../../../services/PortfolioService'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { getCountryListAPI } from '../../../services/commonServices';
import { toast } from 'react-toastify';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
import OpenTrade from './OpenTrade';
import $ from 'jquery'
// import CountryCurrencyList form 


const CreatePortfolio = ({ runAgainDashBoard, setRunAgainDashBoard, userType, setRunPortFolioAPI, providerList, portFolioData, showEdit, setShowEdit, setWatchListRun }) => {

    const [usercountry, setUserCountry] = useState("");
    const [portInputData, setPortInputData] = useState({
        portfolioName: '',
        country: '',
        providerId: '',
        currency: '',
        CurrencySymbol: '',
        userId: userType?.userId,
        reRender: 1
    })
    const [countryList, setCountryList] = useState()
    const [showHideCountry, setShowHideCountry] = useState(false)
    const { portfolioName, currency, country, email, reRender } = portInputData

    useEffect(() => {
        if (showEdit == true) {

            setPortInputData({
                portfolioName: portFolioData?.portfolioName || '',
                country: portFolioData?.country || '',
                providerId: portFolioData?.providerId || '',
                CurrencySymbol: portFolioData?.currencySymbol,
                userId: userType?.userId,
                currency: portFolioData.currency,
                reRender: reRender + 1
            })
            const initialSelectedValue = portFolioData?.country;
            const $selectedItem = $('.dropdown > .list > .item[data-value="' + initialSelectedValue + '"]');
            if ($selectedItem.length) {
                $('.dropdown > .list > .item').removeClass('selected');
                $selectedItem.addClass('selected').parent().parent().removeClass('open').children('.caption').text($selectedItem.text());
            }
        }
    }, [portFolioData != '' && portFolioData && showEdit])

    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    setCountryList(res.data)
                }
            })
        } catch (error) {

        }
    }, [])


    const handleAddEditPortfolio = (value) => {
        const portCancle = document.getElementById('Cancle-portFolio')
        value.userId = userType?.userId
        if (showEdit === true) {
            let id = portFolioData.portfolioId

            if (userType?.userId !== undefined && userType?.userId !== null && userType?.userId.length > 0) {
                try {
                    editPortfoloio(value, id).then((res) => {
                        if (res?.status === 200) {
                            setRunAgainDashBoard(runAgainDashBoard + 1)
                            setWatchListRun(true)
                            portCancle.click()
                        }
                    })
                } catch (error) {

                }
            }
            else {
                toast.error('invalid user!')
            }
        } else {
            if (userType?.userId !== undefined && userType?.userId !== null && userType?.userId.length > 0) {
                try {
                    createPortfoloio(value).then((res) => {
                        if (res?.status === 200) {
                            setWatchListRun(true)
                            setRunPortFolioAPI(true)
                            portCancle.click()
                            setRunAgainDashBoard(runAgainDashBoard + 1)
                        }
                    })
                } catch (error) {

                }
            } else {
                toast.error('invalid user!')
            }
        }

    }
    const handleRemoveInputData = () => {
        const initialSelectedValue = country;
            const $selectedItem = $('.dropdown > .list > .item[data-value="' + initialSelectedValue + '"]');
            if ($selectedItem.length) {
                $('.dropdown > .list > .item').removeClass('selected');
                $selectedItem.removeClass('selected').parent().parent().removeClass('open').children('.caption').text('Select country');
            }
        setPortInputData({
            portfolioName: '',
            country: '',
            providerId: '',
            userId: userType?.userId,
            reRender: reRender + 1
        })
        // setRunAgainDashBoard(runAgainDashBoard+1)
        if (showEdit == true) {
            setShowEdit(false)
        }

    }
    let validationSchema = Yup.object().shape({
        portfolioName: Yup.string().required('Portfolio name is required'),
        country: Yup.string().required('Country is required'),
        // providerId: Yup.string().required('Provider is required'),
    });

    const handleChangePortFolioInput = (e) => {
        if (e.target.name == 'country') {
            let selectedCountry = countryList.filter((val) => val.key == e.target.value)
            setUserCountry(e.target.value)
            let symboleData
            if (selectedCountry.length > 0) {
                symboleData = CountryCurrencyList.filter((val) => val.code == selectedCountry[0].value)
            }
            setPortInputData({ ...portInputData, [e.target.name]: e.target.value, CurrencySymbol: symboleData[0]?.symbol_native, currency: selectedCountry[0]?.value })
        } else {
            setPortInputData({ ...portInputData, [e.target.name]: e.target.value })
        }
    }
    // useEffect(()=>{
    //  
    // },[usercountry])

    const handleBlurPortInput = () => {

    }
    $(function () {

        // $('.dropdown > .caption').on('click', function () {
        //     var dropdown = document.querySelector('.dropdown');
        //     if (dropdown.classList.contains('open')) {
        //         dropdown.classList.remove('open');
        //     } else {
        //         dropdown.classList.add('open');
        //     }
        // });

        $('.dropdown > .list > .item').on('click', function () {
            
            // Assuming you want to use this event to set user country and currency information
            // Update the UI by adding and removing classes
            setShowHideCountry(!showHideCountry);
            $('.dropdown > .list > .item').removeClass('selected');
            $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').text($(this).text());

            // Extract the selected value from the clicked item
            const selectedValue = $(this).text(); // You should set a data-value attribute on your HTML elements

            // Filter the country list based on the selected value
            const selectedCountry = countryList.filter(val => val.key == selectedValue);

            // Set the user's selected country
            setUserCountry(selectedValue);

            let symbolData;

            if (selectedCountry.length > 0) {
                // Filter the currency data based on the selected country's code
                symbolData = CountryCurrencyList.filter(val => val.code == selectedCountry[0].value);
            }

            // Update the state with currency-related information
            setPortInputData({
                ...portInputData,
                country: selectedValue,
                CurrencySymbol: symbolData[0]?.symbol_native,
                currency: selectedCountry[0]?.value
            });
        });


        $(document).on('keyup', function (evt) {
            
            if ((evt.keyCode || evt.which) === 27) {
                setShowHideCountry(!showHideCountry)
                $('.dropdown').removeClass('open');
            }
        });

        $(document).on('click', function (evt) {
            
            if ($(evt.target).closest(".dropdown > .caption").length === 0) {
                setShowHideCountry(!showHideCountry)
                $('.dropdown').removeClass('open');
            }
        });

    });
    const handleShowHideCountry = () => {
        var dropdown = document.querySelector('.dropdown');
        if (dropdown.classList.contains('open')) {
            dropdown.classList.remove('open');
        } else {
            dropdown.classList.add('open');
        }
        setShowHideCountry(!showHideCountry)
    }
    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" data-keyboard="false" id="portfolio" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ "display": 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">{showEdit == true ? 'Edit' : 'Create'} Portfolio</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" aria-label="Close" onClick={handleRemoveInputData}>×</button>
                        </div>

                        <div className="modal-body blackout-bx">

                            <Formik
                                initialValues={portInputData}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(value) => {
                                    handleAddEditPortfolio(value)
                                    // alert(JSON.stringify(value))
                                }}

                            >
                                {({ errors, touched }) => (
                                    <Form className='row p-3'>
                                        {/* <div class="col-sm-12"> */}
                                        <div class="col-12 col-sm-6">
                                            <label>Portfolio Name <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" onBlur={handleBlurPortInput} class="form-control" value={portfolioName} name='portfolioName' onChange={handleChangePortFolioInput} id="autoSizingInput" placeholder="" />
                                            {errors.portfolioName && touched.portfolioName ? (
                                                <div className='color-msg-err'>{errors.portfolioName}</div>
                                            ) : null}
                                        </div>
                                        {/* <div class="col-12 col-sm-6 ms-auto">
                                            <label>Provider Name</label>
                                            <Field name='providerId' as='select' className="form-select" onChange={handleChangePortFolioInput}>
                                                <option value="0" style={{ 'visibility': "hidden" }}>Select Provider</option>
                                                {
                                                    providerList?.map((val, ind) => {
                                                        return (
                                                            <option key={ind} value={(val.providerId)}>{val.providerName}</option>
                                                        )
                                                    })
                                                }
                                            </Field>
                                            {errors.providerId && touched.providerId ? (
                                                <div className='color-msg-err'>{errors.providerId}</div>
                                            ) : null}
                                        </div> */}
                                        <div class="col-12 col-sm-6 position-relative">
                                            <label>Country <span style={{ "color": "#f00" }}>*</span></label>
                                            <div class="select_option_dropDown container">
                                                <div class="select_option_dropDown dropdown" id='showDropDownCountry'>
                                                    <div class="select_option_dropDown caption" onClick={handleShowHideCountry} >Select country</div>
                                                    <div class="select_option_dropDown list">
                                                        <div class="select_option_dropDown item" data-value='' >Option 1</div>
                                                        {
                                                            countryList?.map((val, ind) => {
                                                                return (
                                                                    <div class="select_option_dropDown item" data-value={val.key} >{val.key}</div>
                                                                )
                                                            })
                                                        }
                                                        {/* <div class="item">Optionsdfgfdgcb fgh fghgnghjg hjg nghjghjgh jgjgh jgjg  2</div>
                                                        <div class="item">Option 3</div>
                                                        <div class="item">Option 4</div>
                                                        <div class="item">Option 5</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Field name='country' onBlur={handleBlurPortInput} disabled={showEdit == true ? true : false} as='select' className="form-select" onChange={handleChangePortFolioInput}>
                                                <option value="0" style={{ 'visibility': "hidden" }}>Select Country</option>
                                                {
                                                    countryList?.map((val, ind) => {
                                                        return (
                                                            <option key={ind} value={val.key}>{val.key}</option>
                                                        )
                                                    })
                                                }
                                            </Field> */}
                                            {errors.country && touched.country ? (
                                                <div className='color-msg-err'>{errors.country}</div>


                                            ) : null}

                                            <span className='currency_port'>{currency}</span>

                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6'>


                                                {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEdit === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}
                                                <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit'> {showEdit == true ? 'Update' : 'Save'}</button>


                                            </div>
                                            <div className='col-md-6'>
                                                <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-portFolio' type='button' onClick={handleRemoveInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                            </div>

                                        </div>

                                    </Form>
                                )}

                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePortfolio